import React, { useState } from 'react';
import { DynamicForm } from './DynamicForm';
import SEO from './SEO';

const formSubmitBtnName = 'SEND';
const dialogHeader = 'GPU Upgrade and Parts request';
const successMessage = 'Your request has been sent, we will contact you within the next business day.';
const errorMessage = 'Your request failed to sent, please contact us by e-mail or phone.';
const fields = [
    { prop: 'name', label: 'Full name', placeholder: '', type: 'text', size: 6, validation: 'Please enter your name.', validationtype: 'required' },
    { prop: 'firm', label: 'Company name', placeholder: '', type: 'text', size: 6, validation: '', validationtype: '' },
    { prop: 'email', label: 'E-mail', placeholder: '', type: 'email', size: 6, validation: 'Please enter your email address.', validationtype: 'email' },
    { prop: 'phone', label: 'Phone number', placeholder: '', type: 'text', size: 6, validation: 'Please enter your phone number.', validationtype: 'phone' },
    { prop: 'brand', label: 'Laptop brand', placeholder: '', type: 'text', size: 6, validation: 'Please enter laptop brand.', validationtype: 'required' },
    { prop: 'model', label: 'Laptop model', placeholder: '', type: 'text', size: 6, validation: 'Please enter laptop model.', validationtype: 'required' },    
    { prop: 'message', label: 'Other requirements', placeholder: '', type: 'textarea', size: 12, validation: '', validationtype: '' }
];
let dynamicObject = {};
fields.map(item => dynamicObject[item.prop] = '');

export const Upgrade = () => {
    const [object, setObject] = useState(dynamicObject);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogBody, setDialogBody] = useState('');

    const handleObjectChange = e => {
        const { id, value } = e.target;
        setObject(prevObj => ({ ...prevObj, [id]: value }));
    };
    const onSubmit = () => {
        setLoading(true);
        sendFomrAsync();
    };
    const onCloseDialog = () => {
        setDialogOpen(false);
    }

    const sendFomrAsync = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };
        const response = await fetch('form?id=7', requestOptions);
        if (response.ok) {
            setDialogBody(successMessage);
            setObject(dynamicObject);
        }
        else {
            setDialogBody(errorMessage);
        }
        setLoading(false);
        setDialogOpen(true);
    }

    return (
        <>
            {SEO({ title: "Upgrade MXM CLEVO PARTS | EUROCOM | Goldmax", description: "MXM Upgrade order for CLEVO and EUROCOM." })}
            <h1>GPU Upgrade and Parts</h1>            

            <DynamicForm fields={fields} object={object} submitBtnName={formSubmitBtnName} onChangeObject={handleObjectChange} onSubmitForm={onSubmit} loading={loading} dialogHeader={dialogHeader} dialogBody={dialogBody} dialogOpen={dialogOpen} onCloseDialog={onCloseDialog} />

            <p>Contact us today at <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a> or call us <a href="tel:+420724583823">+420 724 583 823</a> for more details and ask how we can help you succeed in your ICT supplier chain.</p>

            <img className="full-img" src="images/static-content/upgrade_1.jpg" />

            <div className="youtube-container">
                <div className="youtube-video-container">
                    <span>Alienware 17 (R5); Benchmarks; NVIDIA GeForce GTX 980M</span>
                    <iframe className="youtube-video" frameBorder="0" src="https://www.youtube.com/embed/mZkkRfPwpdk"></iframe>
                </div>
                <div className="youtube-video-container">
                    <span>Alienware M17x R4 NVIDIA Quadro K5100M Upgrade - Benchmarks</span>
                    <iframe className="youtube-video" frameBorder="0" src="https://www.youtube.com/embed/-FuFFlVVGZE"></iframe>
                </div>
                <div className="youtube-video-container">
                    <span>Alienware M18x R1; NVIDIA GeForce GTX 880M SLI upgrade</span>
                    <iframe className="youtube-video" frameBorder="0" src="https://www.youtube.com/embed/AitCbvv3lcQ"></iframe>
                </div>
                <div className="youtube-video-container">
                    <span>AW M15x AMD Radeon HD 7970M and NVIDIA GeForce GTX 680M upgrade</span>
                    <iframe className="youtube-video" frameBorder="0" src="https://www.youtube.com/embed/rl81nN8cmdA"></iframe>
                </div>
                <div className="youtube-video-container">
                    <span>Alienware M17x R3/R4; NVIDIA GeForce GTX 780M</span>
                    <iframe className="youtube-video" frameBorder="0" src="https://www.youtube.com/embed/EVdxBnGMXGQ"></iframe>
                </div>
                <div className="youtube-video-container">
                    <span>Alienware M17x R3; NVIDIA GTX 880M/780M; Windows 10</span>
                    <iframe className="youtube-video" frameBorder="0" src="https://www.youtube.com/embed/MxjrR0YIFOI"></iframe>
                </div>
                <div className="youtube-video-container">
                    <span>MSI GT 72 NVIDIA GeForce GTX 980M Upgrade Guide</span>
                    <iframe className="youtube-video" frameBorder="0" src="https://www.youtube.com/embed/ghe4tFuC7jE"></iframe>
                </div>
                <div className="youtube-video-container">
                    <span>Alienware 18 (R3) NVIDIA GeForce GTX 880M SLI upgrade</span>
                    <iframe className="youtube-video" frameBorder="0" src="https://www.youtube.com/embed/P53mSnY3voY"></iframe>
                </div>
                <div className="youtube-video-container">
                    <span>Alienware M17x R4 NVIDIA Quadro K5100M Upgrade Guide</span>
                    <iframe className="youtube-video" frameBorder="0" src="https://www.youtube.com/embed/o9XlijJCWmk"></iframe>
                </div>
                <div className="youtube-video-container">
                    <span>P150EM / P170EM NVIDIA GeForce GTX 980M Upgrade</span>
                    <iframe className="youtube-video" frameBorder="0" src="https://www.youtube.com/embed/NMmQ6FH_IRY"></iframe>
                </div>
                <div className="youtube-video-container">
                    <span>P870DM/P870DM-G NVIDIA GeForce GTX 1070 MXM 3.0 GPU Upgrade</span>
                    <iframe className="youtube-video" frameBorder="0" src="https://www.youtube.com/embed/NMlmnRHx6DE"></iframe>
                </div>
                <div className="youtube-video-container">
                    <span>P870DM/P870DM-G UHD (4K) LCD Assembly Upgrade</span>
                    <iframe className="youtube-video" frameBorder="0" src="https://www.youtube.com/embed/ql-Q9ELlHrU"></iframe>
                </div>
            </div>

            <img className="full-img" style={{ paddingTop: '32px' }} src="images/static-content/upgrade_2.jpg" />
            <img className="full-img" src="images/static-content/upgrade_3.jpg" />
            <img className="full-img" src="images/static-content/upgrade_4.jpg" />
            <img className="full-img" src="images/static-content/upgrade_5.jpg" />
            <img className="full-img" src="images/static-content/upgrade_6.jpg" />
            <img className="full-img" src="images/static-content/upgrade_7.jpg" />
            <img className="full-img" src="images/static-content/upgrade_8.jpg" />
            <img className="full-img" src="images/static-content/upgrade_9.jpg" />
            <img className="full-img" src="images/static-content/upgrade_10.jpg" />
            <img className="full-img" src="images/static-content/upgrade_11.jpg" />
            <img className="full-img" src="images/static-content/upgrade_12.jpg" />
            <img className="full-img" src="images/static-content/upgrade_13.jpg" />
            <img className="full-img" src="images/static-content/upgrade_14.jpg" />
            <img className="full-img" src="images/static-content/upgrade_15.jpg" />
            <img className="full-img" src="images/static-content/upgrade_16.jpg" />
            <img className="full-img" src="images/static-content/upgrade_17.jpg" />
            <img className="full-img" src="images/static-content/upgrade_18.jpg" />
            <img className="full-img" src="images/static-content/upgrade_19.jpg" />
            <img className="full-img" src="images/static-content/upgrade_20.jpg" />
            <img className="full-img" src="images/static-content/upgrade_21.jpg" />
            <img className="full-img" src="images/static-content/upgrade_22.jpg" />
        </>
    );
};