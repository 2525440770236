import React from 'react';
import SEO from './SEO';

export const Lib = () => {
    return (
        <>
            {SEO({ title: "Tutorial and clevo driver | EUROCOM | Goldmax", description: "Product libraby EUROCOM CLEVO Europe." })}
            <h1>Tutorial/Guides/Videos</h1>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>Video library EUROCOM.com</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="https://eurocom.com/ec/videolib(0)ec" target="_blank" rel="noopener noreferrer">
                    https://eurocom.com/ec/videolib(0)ec
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>Official Youtube channel EUROCOM</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="https://www.youtube.com/user/EurocomTechnology" target="_blank" rel="noopener noreferrer">
                    https://www.youtube.com/user/EurocomTechnology
                </a>
            </p>
        </>
    );
};