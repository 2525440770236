import React, { Component } from 'react';

export class ErrorNotFound extends Component {
    static displayName = ErrorNotFound.name;

    constructor(props) {
        super(props);        
    }

    render() {
        return (
            <div className="notFoundContainer">
                <h1 className="notFoundTitle">404</h1>
                <h3 className="notFoundSubTitle">PAGE NOT FOUND</h3>
                <p className="notFoundDesc">
                    Try one of the links in the menu, or return to the previous page.
                </p>
            </div>
        );
    }
}