import React from 'react';
import SEO from './SEO';
import './Students.css';

export const Students = () => {
    return (
        <>
            {SEO({ title: "Clevo for students | EUROCOM | Goldmax", description: "Cheap clevo laptop for student custom made discounted." })}
            <h1>Student discounts</h1>
            <p>If you are a student, professor or academic employee Feel free to ask for 10% Discount. We have experience with special Universities projects all over the world. </p>
            <img className="full-img" src="images/static-content/students_2.jpg" />
            <img style={{ paddingBottom: '44px' }} className="full-img" src="images/static-content/students_1.jpg" />    

            <div className="s-third-con-l">
                <img className="full-img" src="images/static-content/students_3.jpg" />  
                <h5>College & Universities</h5>
                <p>You deserve a high performance machine that can keep up with the demands of your education (and to stay ahead your peers).</p>
            </div>
            <div className="s-third-con-c">
                <img className="full-img" src="images/static-content/students_4.jpg" />   
                <h5>Elementary & High School</h5>
                <p>All Eurocom systems are designed intelligently. The earlier you start, the more flexible and intelligent you will become!</p>
            </div>
            <div className="s-third-con-r">
                <img className="full-img" src="images/static-content/students_5.jpg" />    
                <h5>Post Graduation & Beyond</h5>
                <p>The learning, research and development doesn't stop: a high end Eurocom system enables you the competitive advantage you need to create and succeed long-term.</p>
            </div>
            <div style={{ clear: 'both' }}></div>
        </>
    );
};