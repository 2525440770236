import React, { useState } from 'react';
import { DynamicForm } from './DynamicForm';
import SEO from './SEO';

const formSubmitBtnName = 'SEND';
const dialogHeader = 'Cleaning and Maintenance request';
const successMessage = 'Your request has been sent, we will contact you within the next business day.';
const errorMessage = 'Your request failed to sent, please contact us by e-mail or phone.';
const fields = [
    { prop: 'name', label: 'Full name', placeholder: '', type: 'text', size: 6, validation: 'Please enter your name.', validationtype: 'required' },
    { prop: 'firm', label: 'Company name', placeholder: '', type: 'text', size: 6, validation: '', validationtype: '' },
    { prop: 'email', label: 'E-mail', placeholder: '', type: 'email', size: 6, validation: 'Please enter your email address.', validationtype: 'email' },
    { prop: 'phone', label: 'Phone number', placeholder: '', type: 'text', size: 6, validation: 'Please enter your phone number.', validationtype: 'phone' },
    { prop: 'street', label: 'Street address', placeholder: 'House number and street name', type: 'text', size: 6, validation: 'Please enter your street address.', validationtype: 'required' },
    { prop: 'zipcode', label: 'ZIP', placeholder: '', type: 'number', size: 6, validation: 'Please enter your ZIP.', validationtype: 'required' },
    { prop: 'city', label: 'City', placeholder: '', type: 'text', size: 6, validation: 'Please enter your city.', validationtype: 'required' },
    { prop: 'country', label: 'Country', placeholder: '', type: 'text', size: 6, validation: 'Please enter your country.', validationtype: 'required' },
    { prop: 'serialnumber', label: 'Serial number', placeholder: '', type: 'text', size: 12, validation: 'Please enter serial number.', validationtype: 'required' },
    { prop: 'message', label: 'Your message', placeholder: '', type: 'textarea', size: 12, validation: '', validationtype: '' }
]; 
let dynamicObject = {};
fields.map(item => dynamicObject[item.prop] = '');

export const Cleaning = () => { 
    const [cardIndex, setCardIndex] = useState(1);
    const [object, setObject] = useState(dynamicObject);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogBody, setDialogBody] = useState('');    

    const handleObjectChange = e => {
        const { id, value } = e.target;              
        setObject(prevObj => ({...prevObj, [id]: value }));
    };
    const onSubmit = () => {   
        setLoading(true);
        sendFomrAsync();
    };
    const onCloseDialog = () => {
        setDialogOpen(false);
    }

    const sendFomrAsync = async () => {
        object['variant'] = cardIndex;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };
        const response = await fetch('form?id=1', requestOptions);         
        if (response.ok) {   
            setDialogBody(successMessage);
            setObject(dynamicObject);
        }
        else {
            setDialogBody(errorMessage);
        } 
        setLoading(false);
        setDialogOpen(true);        
    }

    return (
        <>
            {SEO({ title: "Cleaning maintenance replacement | EUROCOM | CLEVO", description: "Clevo maintenance tips for Premium Custom made laptop for linux solution." })}
            <h1>Laptop Cleaning and Maintenance</h1>
            <p>As laptop owner you want your lappy to run long, cool and quietly. Our maintenance service package is exactly what you need as laptop without care tend to run loud, overheats GPU and/or CPU and has much shorter lifespan. We have two option packages to choose from:</p>

            <div className="card-button-container">
                <div className={cardIndex === 1 ? "card-s-6 card-active" : "card-s-6"} onClick={() => setCardIndex(1)}>
                    <h5>BASIC</h5>
                    <ul>
                        <li style={{ fontSize: '14px' }}>Laptop Pick-up at your home/company location within EU</li>
                        <li style={{ fontSize: '14px' }}>Cooling removal (fans, heatsinks, pads) and dust cleaning</li>
                        <li style={{ fontSize: '14px' }}>Reapplication of Premium Thermal paste Kryonaut on CPU/GPU</li>
                        <li style={{ fontSize: '14px' }}>Stress test and stability test with temp check</li>                                              
                    </ul>
                    <p style={{ marginBottom: 0 }}>199 EUR VAT ex.</p>
                    <p style={{ fontSize: '14px' }}>+ Shipping Fee (depending on location)</p>
                </div>
                <div className={cardIndex === 2 ? "card-s-6 card-active" : "card-s-6"} onClick={() => setCardIndex(2)}>
                    <h5>ADVANCED</h5>
                    <ul>
                        <li style={{ fontSize: '14px' }}>Package <b>BASIC</b></li>
                        <li style={{ fontSize: '14px' }}>Fresh Windows O/S istallation and updates</li>
                        <li style={{ fontSize: '14px' }}>Driver installation and check</li>
                        <li style={{ fontSize: '14px' }}>BIOS Update if necessary</li>
                    </ul>
                    <p style={{ marginBottom: 0 }}>249 EUR VAT ex.</p>
                    <p style={{ fontSize: '14px' }}>+ Shipping Fee (depending on location)</p>
                </div>
            </div>

            <DynamicForm fields={fields} object={object} submitBtnName={formSubmitBtnName} onChangeObject={handleObjectChange} onSubmitForm={onSubmit} loading={loading} dialogHeader={dialogHeader} dialogBody={dialogBody} dialogOpen={dialogOpen} onCloseDialog={onCloseDialog} />

            <p>Contact us today at <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a> or call us <a href="tel:+420724583823">+420 724 583 823</a> for more details and ask how we can help you succeed in your ICT supplier chain.</p>
        </>
    );
};