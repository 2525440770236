import React from 'react';
import SEO from './SEO';

export const GDPR = () => {
    return <>
        {SEO({ title: "Privacy Policy | EUROCOM | Goldmax", description: "High-end custom laptops. Flexible and unique solution." })}
        <h1>GDPR Privacy Statement</h1>
        <h5>Protection of personal data</h5>
        <p>Goldmax Services s.r.o. is committed to respecting and protecting your privacy. Goldmax Services s.r.o. has undertaken to protect your personal data and proceeds in accordance with Act No. 101/2000 Coll., on the protection of personal data and on the amendment of certain acts.</p>
        <p>Goldmax Services s.r.o. is registered with the Office for Personal Data Protection.</p>
        <h5>Security of personal data</h5>
        <p>Goldmax Services s.r.o. is committed to protecting and securing your personal information. We use a variety of security technologies and procedures to protect your personal information from unauthorized access, use or disclosure. If you provide us with your personal information when ordering products or services, you can be assured that we will only use it to ensure and maintain contact between you and our company. Goldmax Services s.r.o. will not sell or rent your personal information to anyone else in any way. Unless we have your consent or the law requires otherwise, we will not share your personal data that you provide to us online.</p>
        <h5>Where personal data is required</h5>
        <p>Goldmax Services s.r.o. publishes on its website information not only about the products and services it offers, but also information about contacts to our company, basic information about our company, job offers, etc. All this information is available without requiring any personal information from site visitors.</p>
        <h5>The data we collect</h5>
        <p>Goldmax Services s.r.o. operates a site where you can order products or services, submit requests and register to receive materials. Personal information collected on this site is billing information, delivery address, contact person, telephone number, email address and business transactions.</p>
        <h5>Why we collect this data</h5>
        <p>Goldmax Services s.r.o. will collect and use your personal information only with your knowledge and consent, for the ordering or subsequent use of products and services. We may use your information for purposes related to the provision of services to you, which includes processing orders, concluding contracts, providing services and products, or information you request or answer your complaints or questions.</p>
        <h5>Consent to the provision of personal data</h5>
        <p>Goldmax Services s.r.o. obtains your voluntary consent to the inclusion of the provided data in the company's database and their further processing of registrations on our website. If you choose to provide us with personal information (any personally identifiable information), please be assured that it will only be used to support you as our customer.</p>
    </>
} 