import React, { Component } from 'react';
import Loader from './Loader';
import './NotebooksOverview.css';
import { Route } from 'react-router-dom';
import { ErrorNotFound } from './ErrorNotFound';
//import { AppStateContext } from './AppStateContext'
import { GetCurrency } from './Configurator'
import { AppStateContext } from './AppStateContext'
import SEO from './SEO';

export class NotebooksOverview extends Component {
    static displayName = NotebooksOverview.name;
    static contextType = AppStateContext;

    constructor(props) {
        super(props);        
        this.state = { notebooks: null, loading: true, scmodel: null };
        this.handleConfigButton = this.handleConfigButton.bind(this); 
        this.handleDetail = this.handleDetail.bind(this); 
        this.handleShoppingCart = this.handleShoppingCart.bind(this); 

        this.type = -1;
        /*if (typeof this.props.location.state != 'undefined') {
            this.type = this.props.location.state.type;            
        }*/
        /*
        const { type } = this.props.match.params;
        switch (type) {
            case "top":
                this.type = 0;
                break;
            case "workstation":
                this.type = 1;
                break;
            case "server":
                this.type = 2;
                break;
            case "enterprise":
                this.type = 3;
                break;
            case "gaming":
                this.type = 4;
                break;
            case "configured":
                this.type = 99;
                break;
            default:
                this.type = -1;
                break;
        }
        */
        const { pathname } = this.props.location;
        switch (pathname) {
            case "/mobile-supercomputers": //"top":
                this.type = 0;
                break;
            case "/mobile-workstations": //"workstation":
                this.type = 1;
                break;
            case "/mobile-servers": //"server":
                this.type = 2;
                break;
            case "/enterprise-laptops": //"enterprise":
                this.type = 3;
                break;
            case "/gaming-laptops": //"gaming":
                this.type = 4;
                break;
            case "/notebooks-in-stock": //"configured":
                this.type = 99;
                break;
            default:
                this.type = -1;
                break;
        }

    }
    handleConfigButton(e) { 
        e.stopPropagation();
        const { id } = e.target;
        //this.props.history.replace('/configurator', { id: id, type: this.type });    - HACK PROHLIZECE - TLACITKO Z5
        //this.props.history.push('/configurator', { id: id, type: this.type });
        this.props.history.push('/configurator/' + id, { type: this.type });
        window.scrollTo(0, 0);
    }
    handleDetail(e) {
        const { id } = e.target;
        //this.props.history.push('/detail/' + id, { type: this.type });
        this.props.history.push('/' + id, { type: this.type });
        window.scrollTo(0, 0);
    }
    async getConfiguratorAsync(id) {
        const requestOptions = { method: 'GET', 'Content-Type': 'application/json' };
        const response = await fetch('configurator/' + id, requestOptions);
        const data = await response.json();
        this.setState({ scmodel: data, loading: false });
    }
    async handleShoppingCart(e) {
        e.stopPropagation();
        this.setState({ loading: true });
        const { id } = e.target;
        await this.getConfiguratorAsync(id);
        let keys = Object.keys(localStorage);

        //keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();
        //localStorage.setItem(keys.length, JSON.stringify(this.state.scmodel));
        //keys = Object.keys(localStorage);
        //keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();
        //this.context[1]((prevState) =>
        //    ({ ...prevState, scart: keys.length })
        //);

        keys = keys.filter(x => x.includes("gmscitem")).sort();
        var tempModel = this.state.scmodel;
        tempModel.notebook.detail = null;

        localStorage.setItem(`gmscitem-${keys.length}`, JSON.stringify(tempModel));
        /* ZBYTECNE - NEMELO BY NASTAT TO ZE PRIBYDE VICE JAK JEDEN PRVEK 
         * keys = Object.keys(localStorage);
        keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();
        this.context[1]((prevState) =>
            ({ ...prevState, scart: keys.length })
        );
        */
        this.context[1]((prevState) =>
            ({ ...prevState, scart: keys.length + 1 })
        );



        this.props.history.push('/shoppingcart');
        window.scrollTo(0, 0);
    }

    componentDidMount() {          
        this.getNotebooksAsync();        
    }
    componentWillReceiveProps(props) {        
        /*this.type = props.location.state.type;*/
        /*
        const { type } = props.match.params;
        switch (type) {
            case "top":
                this.type = 0;
                break;
            case "workstation":
                this.type = 1;
                break;
            case "server":
                this.type = 2;
                break;
            case "enterprise":
                this.type = 3;
                break;
            case "gaming":
                this.type = 4;
                break;
            case "configured":
                this.type = 99;
                break;
            default:
                this.type = -1;
                break;
        }
        */
        const { pathname } = props.location;
        switch (pathname) {            
            case "/mobile-supercomputers": //"top":
                this.type = 0;
                break;
            case "/mobile-workstations": //"workstation":
                this.type = 1;
                break;
            case "/mobile-servers": //"server":
                this.type = 2;
                break;
            case "/enterprise-laptops": //"enterprise":
                this.type = 3;
                break;
            case "/gaming-laptops": //"gaming":
                this.type = 4;
                break;
            case "/notebooks-in-stock": //"configured":
                this.type = 99;
                break;
            default:
                this.type = -1;
                break;

        }
        this.setState({ notebooks: null, loading: true });
        this.getNotebooksAsync();       
    }
    static renderDescription(notebook) {
        let description = notebook.description + "";
        let descriptions = [];        
        if (typeof description !== 'undefined' && description != "") {
            descriptions = description.split(";");
        }
        return (            
            descriptions.map(desc =>
                <div id={notebook.urlName} key={notebook.id}>{desc}</div>
            )            
        );
    }
    
    static renderNotebooksItems(_this) {
        return (
            _this.state.notebooks.map(notebook => (
                <React.Fragment>                    
                    <div className="item" id={notebook.urlName} key={notebook.id} onClick={_this.handleDetail}>
                        <img id={notebook.urlName} key={notebook.id} className="item-img" src={notebook.notebookImages ? "https://www.goldmax.cz/" + notebook.notebookImages[0].link : ""}/>
                        <h3 id={notebook.urlName} key={notebook.id}>{notebook.nameEn}</h3>
                        {/*<div id={notebook.id} key={notebook.id} className="price-container">*/}
                        {/*    <span id={notebook.id} key={notebook.id} className="price-text">PRICE</span>*/}
                        {/*    {_this.type == 99 /*CONFIGUREELNY VS NEKON....*/}
                        {/*        ? <span id={notebook.id} key={notebook.id} class="price-value"><GetCurrency value={notebook.price} /></span>*/}
                        {/*        : <span id={notebook.id} key={notebook.id} class="price-value">from <GetCurrency value={notebook.price} /></span>*/}
                        {/*    }*/}
                        {/*</div>*/}

                        {/*<div id={notebook.id} key={notebook.id} className="item-text-container">*/}
                        {/*    {NotebooksOverview.renderDescription(notebook)}*/}
                        {/*</div>  */}
                        {/*{_this.type == 99 /*CONFIGUREELNY VS NEKON....*/}
                        {/*    ? <button class="main-button rounded" id={notebook.id} key={notebook.id} onClick={_this.handleShoppingCart}>ADD TO CART</button>*/}
                        {/*    : <button class="main-button rounded" id={notebook.id} key={notebook.id} onClick={_this.handleConfigButton}>CONFIGURE</button>*/}
                        {/*}                                                                                              */}
                        <div id={notebook.urlName} key={notebook.id} className="price-container">
                            <span id={notebook.urlName} key={notebook.id} className="price-text">PRICE</span>
                            {_this.type == 99 /*KONFIGUROVATELNY VS NEKON....*/
                                ?
                                notebook.discountPrice != 0
                                    ? <>

                                        <div id={notebook.urlName} key={notebook.id} class="price-value">
                                            <span className="overview-canceled-price"><GetCurrency value={notebook.price} withoutCurrencySymbol={true} /></span>
                                            <span className="price-value overview-discount-price"><GetCurrency value={notebook.discountPrice} /></span>
                                        </div>
                                        <span className="overview-discount-note"><b>{notebook.discountNote}</b></span>
                                    </>
                                    : <span id={notebook.urlName} key={notebook.id} class="price-value"><GetCurrency value={notebook.price} /></span>

                                : <span id={notebook.urlName} key={notebook.id} class="price-value">od <GetCurrency value={notebook.price} /></span>
                            }
                        </div>

                        <div id={notebook.urlName} key={notebook.id} className="item-text-container">
                            {NotebooksOverview.renderDescription(notebook)}
                        </div>
                        {_this.type == 99 /*KONFIGUROVATELNY VS NEKON....*/
                            ? <button class="main-button rounded" id={notebook.id} key={notebook.id} onClick={_this.handleShoppingCart}>ADD TO CART</button>
                            : <button class="main-button rounded" id={notebook.urlName} key={notebook.id} onClick={_this.handleConfigButton}>CONFIGURE</button>
                        }
                    </div>
                </React.Fragment>
            ))
        );
    }

    render() { 
        /*if (typeof this.props.match.params == 'undefined') {
            return <Route path="*" component={ErrorNotFound} />
        }*/
        if (typeof this.props.match.params == 'undefined') {
            return <Route path="*" component={ErrorNotFound} />
        }
        let contents = this.state.loading
            ? <Loader loading={this.state.loading} />
            : NotebooksOverview.renderNotebooksItems(this);

        return (
            <React.Fragment>
                {this.getSEO(this.type)}
                <h1>{this.getHeader(this.type)}</h1>
                <div className="item-container">
                    {contents}
                </div>
                {this.getContent(this.type)}
                {this.type != 99 && Array.isArray(contents) && this.getImages(this.type)}                   
            </React.Fragment>
        ); 
    }

    async getNotebooksAsync() {        
        const requestOptions = { method: 'GET', 'Content-Type': 'application/json' };
        const response = await fetch('notebook/getall/' + this.type, requestOptions);
        const data = await response.json();
        this.setState({ notebooks: data, loading: false });
    }

    getSEO(type) {
        switch (type) {
            case 0:
                return SEO({ title: "Best high performance laptops | EUROCOM | Goldmax", description: "CAD CAM GIS workstations for Adobe Premiere, CATIA and Rendering." });
            case 1:
                return SEO({ title: "Cad Mobile Workstations | EUROCOM | Goldmax", description: "CAD CAM GIS PLM laptop movile workstations for Adobe Premiere, CATIA and Rendering in 4K 8K." });
            case 2:
                return SEO({ title: "Mobile Server | EUROCOM | Goldmax", description: "Mobile computer server business solutin." });
            case 3:
                return SEO({ title: "Business laptop | EUROCOM | Goldmax", description: "Light thing high performance laptop with docking station long battery life." });
            case 4:
                return SEO({ title: "Gaming laptops | EUROCOM | Goldmax", description: "Ultimate MXM based gaming uber laptop with high performance GPU 3080 4080 by CLEVO." });
            case 99:
                return SEO({ title: "Prebuild laptops eurocom europe | EUROCOM | Goldmax", description: "Clevo laptop pre-build in stock for sale, custom made shipping Europe." });
            default: return "";
        }
    }

    getHeader(type) {
        switch (type) {
            case 0:
                return "Mobile Supercomputers";
            case 1:
                return "Mobile Workstations";
            case 2:
                return "Mobile Servers";
            case 3:
                return "Enterprise Laptops";
            case 4:
                return "Gaming Laptops";
            case 99:
                return "In Stock/On SALE";
            default: return "";
        }
    }
    getContent(type) {
        switch (type) {
            case 0:
                return <div className="paragraph-container">
                    <h2>Looking for a powerful work laptop for demanding photo editing, development or 4K/8K video editing?</h2>
                    <h2>The latest professional laptop for rendering objects in Adobe SW, Catia V5 or 3D modelling?</h2>
                    <h2>Do you develop software on a laptop, work with demanding 3D graphics or deal with PLC programming?</h2>
                    <p>EUROCOM will build you a customized notebook in the TOP notebook category configurator, which we will build, configure and tune to perfection together. Our TOP laptops are far behind the competition of stock AND branded models that are lying pre-configured in warehouses trying to look professional. We ship laptops with RTX 3080 and Intel Core i9 on a regular basis.</p>
                    <p>The most powerful notebook on the market is regularly supplied by EUROCOM, which can also be kept within reasonable noise levels and the highest series are fitted with DESKTOP components. What does this mean? Simply that we put full desktop PC components into the highest-end notebooks that push the bar higher and keep us 2-3 years ahead of the competition in terms of performance. 128GB RAM, up to 32 core processors, 20TB SSDs, the most powerful graphics and Windows Professional, we can and we do.</p>
                    {this.whyBuyFromGoldmax()}
                </div>;
            case 1:
                return <div className="paragraph-container">
                    <h2>Looking for a powerful workstation for photo editing, development or 4K/8K video editing?</h2>
                    <h2>Latest professional laptop for modelling in CAD software like Solidworks, PLM software or BIM application for architects?</h2>
                    <h2>Is your job to render models or do you program on a professional level in LINUX?</h2>
                    <p>EUROCOM will build you a customized notebook in the Workstation category configurator, which we will build, configure and fine-tune together to perfection. Our EUROCOM workstations are far beyond the competition of stock and branded models that sit pre-configured in warehouses trying to look professional.</p>
                    <p>The most powerful notebook on the market is regularly supplied by EUROCOM, which can also be kept within reasonable noise levels and the highest series are fitted with DESKTOP components. What does this mean? Simply that we put full desktop PC components into the highest-end notebooks that push the bar higher and keep us 2-3 years ahead of the competition in terms of performance. 128GB RAM, up to 32 core processors, 20TB SSDs, the most powerful graphics and Windows Professional, we can and we do. We put NVIDIA Quadro in laptops or RTX 3080 in laptops on a regular basis.</p>
                    {this.whyBuyFromGoldmax()}
                </div>;
            case 2:
                return <div className="paragraph-container">
                    {this.whyBuyFromGoldmax()}
                </div>;
            case 3:
                return <div className="paragraph-container">
                    {this.whyBuyFromGoldmax()}
                </div>;
            case 4:
                return <div className="paragraph-container">
                    <h2>Looking for a powerful gaming laptop for demanding gaming?</h2>
                    <h2>The latest laptop for Virtual Reality?</h2>
                    <h2>Are you developing a game engine or working with 3D graphics?</h2>
                    <p>EUROCOM will build you a customized notebook in the gaming notebook category configurator, which we will build, configure and fine-tune to perfection together. Gaming doesn't just mean gaming, but with powerful hardware that we can also keep within a reasonable noise level. In addition to a powerful graphics card, you can look forward to things like OLED displays, fast LCDs with high refresh rates, and multi-core processors to enhance the gaming experience.</p>
                    <h2>Want you a super extra powerful gaming laptop?</h2>
                    <p>Maybe try the TOP laptops category, which represents the most powerful laptops in the world that offer even more, such as extensive upgrade options, greater resistance to long-term load (rendering, computing...)</p>
                    {this.whyBuyFromGoldmax()}
                </div>;
            case 99:
                return <div className="paragraph-container">
                    {this.whyBuyFromGoldmax()}
                </div>;
            default: return "";
        }
    }

    whyBuyFromGoldmax() {
        return <>
            <h2>Why shop at Eurocom-europe.com?</h2>
            <p>90% of our customers don't just buy whatever they like in the configurator, they consult us. Tell us your budget, the intended use of the laptop, your individual requirements (e.g. size, weight, number of drives, etc.) and we will build you a laptop according to our many years of experience in a way that no stock assemblies from competitors can. Are you buying for someone else and not quite sure of the model and technical specifications? Call <a href="tel:+420724583823">+420 724 583 823</a> or email <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a> and together we'll turn your enquiry into the reality of the perfect laptop today.</p>
            {/*<p>Notebooky také naskladňujeme na <a className="czc-link" target="_blank" href="https://www.czc.cz/eurocom/hledat?dostupnost=skladem">CZC.<span>CZ</span></a>.</p>*/}
        </>;
    }

    getImages(type) {        
        switch (type) {
            case 0:
                return (
                    <div className="full-img-container">
                        <img className="full-img" src="images/ntb-categories/Top1.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top2.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top3.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top4.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top5.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top6.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top7.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top8.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top9.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top10.jpg"></img>
                    </div>);
            case 1:
                return (
                    <div className="full-img-container">
                        <img className="full-img" src="images/ntb-categories/Workstation1.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Workstation2.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Workstation3.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Workstation4.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Workstation5.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Workstation6.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Workstation7.jpg"></img>

                    </div>);
            case 2:
                return (
                    <div className="full-img-container">
                        <img className="full-img" src="images/ntb-categories/Server1.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server2.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server3.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server4.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server5.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server6.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server7.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server8.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server9.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server10.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server11.jpg"></img>                     
                    </div>);
            case 3:
                return (
                    <div className="full-img-container">
                        <img className="full-img" src="images/ntb-categories/Enterprise1.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Enterprise2.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Enterprise3.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Enterprise4.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Enterprise5.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Enterprise6.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Enterprise7.jpg"></img>

                    </div>);
            case 4:
                return (
                    <div className="full-img-container">
                        <img className="full-img" src="images/ntb-categories/Gaming1.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming2.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming3.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming4.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming5.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming6.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming7.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming8.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming9.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming10.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming11.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming12.jpg"></img> 
                    </div>);
            default: return '';
        }
    }
}
