import React, { useState } from 'react';
import { DynamicForm } from './DynamicForm';
import SEO from './SEO';

const formSubmitBtnName = 'SEND';
const dialogHeader = 'RMA request';
const successMessage = 'Your request has been sent, we will contact you within the next business day.';
const errorMessage = 'Your request failed to sent, please contact us by e-mail or phone.';
const fields = [
    { prop: 'name', label: 'Full name', placeholder: '', type: 'text', size: 6, validation: 'Please enter your name.', validationtype: 'required' },
    { prop: 'firm', label: 'Company name', placeholder: '', type: 'text', size: 6, validation: '', validationtype: '' },
    { prop: 'email', label: 'E-mail', placeholder: '', type: 'email', size: 6, validation: 'Please enter your email address.', validationtype: 'email' },
    { prop: 'phone', label: 'Phone number', placeholder: '', type: 'text', size: 6, validation: 'Please enter your phone number.', validationtype: 'phone' },
    { prop: 'street', label: 'Street address', placeholder: 'House number and street name', type: 'text', size: 6, validation: 'Please enter your street address.', validationtype: 'required' },
    { prop: 'zipcode', label: 'ZIP', placeholder: '', type: 'number', size: 6, validation: 'Please enter your ZIP.', validationtype: 'required' },
    { prop: 'city', label: 'City', placeholder: '', type: 'text', size: 6, validation: 'Please enter your city.', validationtype: 'required' },
    { prop: 'country', label: 'Country', placeholder: '', type: 'text', size: 6, validation: 'Please enter your country.', validationtype: 'required' },
    { prop: 'serialnumber', label: 'Serial number', placeholder: '', type: 'text', size: 12, validation: 'Please enter serial number.', validationtype: 'required' },
    { prop: 'bios', label: 'BIOS + KBC/EC Firmware', placeholder: '', type: 'text', size: 12, validation: 'Please enter BIOS version.', validationtype: 'required' },
    { prop: 'message', label: 'Description of problems', placeholder: '', type: 'textarea', size: 12, validation: '', validationtype: '' }
];
let dynamicObject = {};
fields.map(item => dynamicObject[item.prop] = '');

export const RMA = () => {    
    const [object, setObject] = useState(dynamicObject);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogBody, setDialogBody] = useState('');

    const handleObjectChange = e => {
        const { id, value } = e.target;
        setObject(prevObj => ({ ...prevObj, [id]: value }));
    };
    const onSubmit = () => {
        setLoading(true);
        sendFomrAsync();
    };
    const onCloseDialog = () => {
        setDialogOpen(false);
    }

    const sendFomrAsync = async () => {        
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };
        const response = await fetch('form?id=2', requestOptions);
        if (response.ok) {
            setDialogBody(successMessage);
            setObject(dynamicObject);
        }
        else {
            setDialogBody(errorMessage);
        }
        setLoading(false);
        setDialogOpen(true);
    }

    return (
        <>
            {SEO({ title: "RMA | EUROCOM | CLEVO GERMANY FRANCE POLAND", description: "RMA repair center for CLEVO and EUROCOM Europe." })}
            <h1>RMA Request form</h1>
            <p>Do you have any troubles or issues with your laptop? Do you have technical support requests and questions?</p>
            <p>Any laptop owner can fill in RMA Request form and We will contact you within next bussiness day. After short diagnosis of your problem We will either help you solve it via instructions of our technical support or schedule a Pick-up of your notebook and fix it in our warehouse.</p>
            <p>Please note that laptops without warranty applies to diagnosis fee 99 EUR + actual costs that must be approved by laptop owner. Shipping fee are excluded from RMA costs and wil be dependand on clients location within EU.</p>

            <DynamicForm fields={fields} object={object} submitBtnName={formSubmitBtnName} onChangeObject={handleObjectChange} onSubmitForm={onSubmit} loading={loading} dialogHeader={dialogHeader} dialogBody={dialogBody} dialogOpen={dialogOpen} onCloseDialog={onCloseDialog} />

            <p>Contact us today at <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a> or call us <a href="tel:+420724583823">+420 724 583 823</a> for more details and ask how we can help you succeed in your ICT supplier chain.</p>
        </>
    );
};