import React from 'react';
import SEO from './SEO';
import "./Terms.css";

export const Terms = () => {    
    return <div className="terms-container">
        {SEO({ title: "Terms and Conditions | EUROCOM | Goldmax", description: "High-end custom laptops. Flexible and unique solution." })}
        <h1>I. Basic Provisions</h1>
        <p>The following Terms and Conditions (<strong>Business Terms and Conditions</strong>) regulate the relationships of parties to a purchase/licence agreement entered into by and between <strong>Goldmax Services s.r.o.</strong>, Co. Reg. No. 28572807, VAT Reg. No. CZ28572807, with its registered office at Dolany 616, Dolany 783 16, Czech Republic, registered in the Commercial Register with the Municipal Court in Ostrava, File No. 43781 as the seller on the one part (<strong>Eurocom-Europe.com</strong>) and the purchaser on the other part (<strong>purchaser</strong>).</p>
        <p>Detailed information about Eurocom-Europe.com is available at www.eurocom-europe.com.</p>
        <p><b>Purchaser</b> is a consumer or an entrepreneur.</p>
        <p><b>Consumer</b> is a private individual acting in a private capacity, entering into an agreement or otherwise dealing with Eurocom-Europe.com.</p>
        <p><b>Entrepreneur</b> is an individual licensed to get engaged in business on one’s own account and responsibility with an intention to generate profit on a continuous basis. For the purposes of consumer protection, an entrepreneur is (including but not limited to) an individual entering into contracts / agreements relating to the entrepreneur’s business, manufacturing or similar activities or employment; or an individual acting on behalf or on account of an entrepreneur in question. For the purposes hereof, an entrepreneur is an individual acting in compliance with the preceding sentence as part of one’s own business. If completing their registration number in the order, the purchasers acknowledge that they are bound by the rules stipulated in the Business Terms and Conditions for entrepreneurs.<br />By placing the order, the purchaser confirms that it has become familiar with these General Business Terms and Conditions prior to entering into the agreement, namely with the representations and warranties given in Part II hereof, as well as with the Claims Code and Delivery Information constituting an integral part hereof, and explicitly agrees to the same in the wording valid and effective as at the moment of placing the order.</p>
        <p>The purchaser acknowledges that it is not entitled to use the registered trademarks, trade names, company logos, etc. of Eurocom-Europe.com or Eurocom-Europe.com´ s contractual partners by virtue of having purchased the products offered by Eurocom-Europe.com, unless agreed otherwise under a specific agreement with reference to individual cases.</p>
        <p>When accepting the goods and/or services, the purchaser is issued an invoice including the basic details of the agreement.</p>

        <h1>II. Pre-Contractual Representations and Warranties</h1>
        <h5>Eurocom-Europe.com represents and warrants that:</h5>
        <ol className="lower-latin">
            <li>The cost of distance communication is subject to no additional fees (Eurocom-Europe.com charges no additional fees to the amount charged by the purchaser’s internet / telephone provider; unlike in case of contractual deliveries).</li>
            <li>The purchaser is required to pay the purchase price before accepting the goods from Eurocom-Europe.com or make an advance or similar payment for specific services if the same are offered by Eurocom-Europe.com and requested by the purchaser.</li>
            <li>Eurocom-Europe.com enters into no ongoing contracts. If such contracts are facilitated by Eurocom-Europe.com, the purchaser receives information about the shortest binding term of such contract from the provider in question, along with the price (or method of its determination) charged in one billing period (being at all times one month if such price is constant).</li>
            <li>Unless stipulated otherwise, the licence fee stipulated in a licence agreement entered into for an indefinite period is agreed for the entire licence term.</li>
            <li>The prices for goods and services displayed on the website operated by Eurocom-Europe.com are quoted incl. and excl. VAT, with all statutory fees (if any). Shipping costs, however, vary with reference to the selected shipping method, carrier and payment method.</li>
            <li>The prices for goods and services displayed on the website operated by Eurocom-Europe.com are quoted incl. and excl. VAT, with all statutory fees (if any). Shipping costs, however, vary with reference to the selected shipping method, carrier and payment method.
                <ol className="lower-roman">
                    <li>receiving the goods (purchase agreements);</li>
                    <li>receiving the last delivery (agreements for the purchase of several types of goods / delivery of several parts);</li>
                    <li>or receiving the first delivery (ongoing contracts);</li>    
                </ol>
            </li>
            <span>and do so in writing either by sending the notice of withdrawal to the Eurocom-Europe.com central office or completing the form available on the Eurocom-Europe.com website.</span>
            <li>The consumer cannot withdraw from an agreement for the:
                <ol className="lower-roman">
                    <li>provision of services performed by Eurocom-Europe.com with previous explicit consent of the consumer prior to the expiration of the period of notice;</li>
                    <li>delivery of goods / services offered for the price affected by deviations in the financial market beyond Eurocom-Europe.com´ s control with a potential to occur during the period of notice;</li>
                    <li>delivery of goods adjusted as per the requirements / for the needs of the consumer;</li>
                    <li>delivery of fast moving consumer goods / goods irreversibly mixed with other goods after delivery;</li>
                    <li>repair or maintenance carried out in a place designated by the consumer as per the consumer’s request (save for other than requested repairs / delivery of other than requested spare parts);</li>
                    <li>delivery of goods in a sealed packaging removed by the consumer whereby such goods cannot be returned for sanitary reasons;</li>
                    <li>delivery of audio records / video records / PC programmes whereby the original packing of such products have been broken;</li>
                    <li>delivery of newspapers, periodicals and magazines;</li>
                    <li>transport or leisure services provided by an entrepreneur within a defined period; and</li>
                    <li>delivery of a digital content (delivered other than on a physical carrier with previous explicit consent of the customer prior to the expiration of the period of notice whereby Eurocom-Europe.com underlines that an agreement for the delivery of an on-line content cannot be withdrawn).</li>
                </ol>
            </li>
            <li>The costs incurred in association with returning the goods (withdrawn agreements) / costs of returning the goods other than by ordinary post if the nature of such goods so precludes (withdrawn agreements negotiated away from business premises) are borne by the consumer.</li>
            <li>The consumer is required to pay a pro-rata price for services already commenced to be provided under a withdrawn agreement.</li>
            <li>The agreements (respective invoices) are saved in the electronic archive accessible by the registered Eurocom-Europe.com users directly from their user account.</li>
            <li>Any complaints can be made by completing the contactform or reported to the supervisory / state supervision authorities.</li>          
        </ol> 

        <h1>III. Agreement</h1>
        <h4>1. Execution</h4>
        <p>The purchaser enters into the agreement by accepting the offer to enter into the agreement as displayed on the website operated by Eurocom-Europe.com and in particular by adding the requested product (goods, services, digital content) to the cart or using the “Buy Now” service (registered users only). The purchaser may as well enter into the agreement with the assistance of Eurocom-Europe.com customer service representatives, either by telephone or by email. The purchaser can change the products added to the cart as well as the selected shipping and payment method, i.e. check the order details, before placing a firm order. The purchase agreement is entered into upon the moment the purchaser (having selected the shipping and payment method) submits the order and the order in question is received by Eurocom-Europe.com whereby Eurocom-Europe.com assumes no liability for any mistakes and errors (if any) having occurred during the transfer of data. The purchaser is informed about the executed agreement in a confirmation email letter sent by Eurocom-Europe.com to the email address provided by the purchaser.</p>
        <p>The confirmation email letter is sent along with the Eurocom-Europe.com General Business Terms and Conditions and Claims Code in force attached thereto. Unless stated in the General Business Terms and Conditions otherwise, the executed agreement (incl. agreed price) may be amended or terminated only with the agreement of the parties or in cases stipulated by law.</p>
        <p>The executed agreement is archived by the seller for at least five years from the execution day, however, not longer than for the period stipulated by relevant law. Archived for the performance purposes, the agreement is not additionally available to other than involved third parties. Information about the individual technical steps to be taken in order to enter into an agreement is implied by and the process of entering into an agreement is comprehensibly described in these General Business Terms and Conditions.</p>
       
        <h4>2. Delivery</h4>
        <p>Under the purchase agreement, Eurocom-Europe.com undertakes to deliver the product to the purchaser, provide the digital content/licence to the purchaser and enable the purchaser to acquire the title/licence to the product/digital content purchased under the purchase agreement; the purchaser undertakes to receive the product/digital content from and pay the purchase price to Eurocom-Europe.com.</p>
        <p>As Eurocom-Europe.com reserves the right of ownership to the product, the purchaser acquires the title to the product only after the purchase price has been paid in full. The same applies with necessary modifications also to the purchased licences or services.</p>
        <p>Eurocom-Europe.com delivers the product to the purchaser along with relevant documents pertaining thereto and enables the purchaser to acquire the title to the product/licence in compliance with the agreement.</p>
        <p>Eurocom-Europe.com meets its obligation to deliver the product to the purchaser by enabling the purchaser to view and try the product at the place of performance and informing the purchaser thereabout in due course.</p>
        <p>Products to be shipped by Eurocom-Europe.com are delivered to the purchaser (entrepreneur) by submitting the product to the first shipping company and enabling the purchaser to claim the rights implied by the shipping agreement with the shipping company. Products designated for purchasers-consumers are delivered by Eurocom-Europe.com to the purchasers-consumers only after Eurocom-Europe.com receives the product in question from the shipping company.</p>
        <p>If more items are delivered in excess of the amount agreed, the purchase agreement is deemed entered into for the number of items delivered; unless the purchaser rejects such items without undue delay.</p>
        <p>Eurocom-Europe.com delivers the purchased product to the purchaser in the agreed amount, quality and design.</p>
        <p>If not agreed otherwise, the product is packed by Eurocom-Europe.com by convention; if no convention exists, the product in question is packed as to provide for its protection and preservation. The same applies also to goods to be shipped.</p>

        <h4>3. Risk of Damage</h4>
        <p>The product is defective if lacking the agreed characteristics. The product is deemed defective also in cases where the purchaser is delivered other than the ordered product or where the documents necessary for the use of the product in question contain defects.</p>
        <p>The purchaser can claim improper performance with reference to the defect inherent to the product at the moment the risk of damage passes to the purchaser although the defect in question becomes evident only later. The purchaser can claim the rights also with reference to a defect that has become evident later as result of a breach of Eurocom-Europe.com obligation.</p>
        <p>The purchaser is to examine the product, its characteristics and quantity as soon as feasible after the risk of damage to the product passes to the purchaser.</p>
        <p>The risk of damage passes to the purchaser upon accepting the product in question; the same applies also to cases where the purchaser rejects to accept the goods although having been enabled by Eurocom-Europe.com to view and try the product.</p>
        <p>Damage to the product occurring after the risk of damage has passed to the purchaser does not affect the purchaser’s obligation to pay the purchase price unless the damage occurred as result of a breach of Eurocom-Europe.com obligation.</p>
        <p>If one party is delayed in accepting the product, the other party is entitled to reasonably sell the product at the expense of the delayed party and do so following a prior notice and providing the delayed party with an additional reasonable period to accept the product. The same applies also to delays on payment where the product cannot be accepted unless the purchase price is paid.</p>
       
        <h4>4. Liability (Eurocom-Europe.com)</h4>
        <h5>Eurocom-Europe.com is liable to the purchaser that the product is free from defects upon receipt. In particular, Eurocom-Europe.com is liable to the purchaser that, at the moment the product is received by the purchaser:</h5>
        <ol className="lower-latin">
            <li>The product has the characteristics agreed by the parties or (if no agreement is reached) the characteristics described by Eurocom-Europe.com or the manufacturer or expected by the purchaser with reference to the nature of the goods and related advertisement.</li>
            <li>The product can be used for the purposes stated by Eurocom-Europe.com or for the purposes for which the product of that kind is usually used.</li>
            <li>The product is provided in the corresponding quantity, measurement or weight.</li>
            <li>The product complies with the requirements stipulated by law.</li>            
        </ol>
        <p>If the product becomes defective within six months after receipt, the product is deemed being defective already upon receipt.</p>
        <h5>Unless stipulated otherwise, the purchaser is entitled to claim defective consumer goods within 24 month after receipt. This does not apply to:</h5>
        <ol className="lower-latin">
            <li>discount-related defects;</li>
            <li>usual wear and tear;</li>
            <li>defects caused by ordinary use or wear and tear and evident at the moment of receipt by the purchaser; or</li>
            <li>cases implied by the nature of the case.</li>          
        </ol>
        <p>The purchaser is entitled to claim defective consumables (cosmetics, drugstore items, etc.) within twenty-four (24) months. If, however, a best before date is indicated on the product in question, this period is shortened and terminates on the date indicated on the packaging.</p>
        <p>The individual periods are stipulated in more detail in the Claims Code.</p>
        <p>Purchasers-entrepreneurs may be subject to a different and prevailing period for claiming improper performance, however, only if such different period is explicitly stated on the goods in question. Such goods are then labelled as follows:</p>
        <p>Improper performance cannot be claimed if the purchaser was aware about the defect before accepting the product or if the defect in question was caused by the purchaser.</p>
        <p>In the event of a defect to be addressed by Eurocom-Europe.com and occurring on a discounted/used product, the purchaser is entitled to a reasonable discount rather than to replacement.</p>


        <h4>5. Material Breach</h4>   
        <h5>If improper performance constitutes a material breach of the agreement, the purchaser is entitled to:</h5>
        <ol className="lower-latin">
            <li>have the defect removed by way of being delivered a new defect-free product or the missing part if such is not unreasonable with respect to the nature of the defect in question; in case of a component part affected by the defect, the purchaser can only claim that the component part in question be replaced; if such is not feasible, the purchaser may withdraw from the agreement; if, however, the above is not reasonable with respect to the nature of the defect in question, especially if the defect can be removed without undue delay, the purchaser is entitled to have the defect removed for free;</li>
            <li>have the defect removed by way of repair;</li>
            <li>be given a reasonable discount on the purchase price; or</li>
            <li>withdraw from the agreement.</li>
        </ol>
        <p>When claiming the defect in question, the purchaser informs Eurocom-Europe.com as to which of the aforementioned options the purchaser has selected and does so either immediately or without undue delay thereafter whereby the selected option can be then changed only if so approved by Eurocom-Europe.com; with the exception of a defect requested by the purchaser to be repaired and subsequently being identified as irreparable. If the defects are not removed by Eurocom-Europe.com within a reasonable period or if the purchaser is informed by Eurocom-Europe.com that the defects in question will not be removed, the purchaser may claim a reasonable discount on the purchase price instead of withdrawing from the agreement or withdraw from the agreement.</p>
        <p>If the purchaser fails to select one of the options mentioned above, the rights implied by immaterial breach apply – see below.</p>
        <p>In addition to cases where Eurocom-Europe.com cannot deliver a new defect-free product, replace the component part or repair the product, purchasers-consumers are entitled to a reasonable discount also in cases where Eurocom-Europe.com fails to remedy the situation within a reasonable period or where the remedy would cause significant inconvenience to the purchaser.</p>
   
        <h4>6. Immaterial Breach</h4>       
        <p>If improper performance constitutes other than material breach, the purchaser is entitled to have the defect removed or to be given a reasonable discount on the purchase price.</p>
        <p>Unless the purchaser claims the discount on the purchase price or withdraws from the agreement, Eurocom-Europe.com may deliver the missing parts or remove the legal defect. Other defects can be removed at Eurocom-Europe.com discretion either by way of repair or delivery of a new product.</p>
        <p>If Eurocom-Europe.com fails to remove the defect in due course or refuses to remove the defect, the purchaser may claim a reasonable discount on the purchase price or withdraw from the agreement whereby the selected option can then be changed only if so approved by Eurocom-Europe.com.</p>
   
        <h4>7. General Breach</h4>       
        <p>The purchaser may claim to have the defect removed by way of being delivered a new thing or a replaced component part also in cases where the defect can be removed but the product cannot be used because of a repeated occurrence of the defect after repair or a larger number of defects. In such cases, the purchaser is entitled to withdraw from the agreement.</p>
        <p>If being delivered a new product, the purchaser returns the original product back to Eurocom-Europe.com (along with all accessories delivered together with the product itself) and does so at Eurocom-Europe.com cost.</p>
        <p>Purchasers failing to report the defect without undue delay after the defect could have been ascertained by the purchaser had the product been examined in due course and with sufficient care will not be adjudicated by court the rights under improper performance. The same applies also to a hidden defect not reported without undue delay after the defect could have been ascertained by the purchaser had the product been examined with sufficient care, however, not later than within two years after the product has been delivered to the purchaser.</p>
    
        <h4>8. Quality Guarantee</h4>      
        <p>In providing the quality guarantee, Eurocom-Europe.com undertakes that the product in question will be fit for the ordinary purpose or will preserve the ordinary characteristics. The same applies also to the guarantee period or best before date indicated on the packaging or advertised. The guarantee may as well be arranged with reference to individual component parts of a product.</p>
        <p>The guarantee period commences on the day the product is delivered to the purchaser. If the product is to be shipped to the purchaser as agreed in the agreement, the guarantee period commences only on the day the product is shipped to the designated place. If the purchased product is to be put into operation by a party other than Eurocom-Europe.com, the guarantee period commences only on the day the product is put into operation, provided that the respective service of putting the product into operation is ordered by the purchaser no later than within three weeks after the product is received and that necessary assistance and co-operation of the purchaser to render this service are provided.</p>
        <p>The purchaser is not entitled to claim guarantee with reference to a defect caused by outer circumstances after the risk of damage has passed to the purchaser.</p>

        <h4>9. Digital Content</h4>
        <p>In using the digital content (including software, PC, gaming console, etc.) purchased from Eurocom-Europe.com, the purchaser is to comply with the obligations stipulated herein and copyright law and specific license terms for that product (eg. EULA). Purchasers failing to comply with those obligations are liable to damages and may face criminal consequences.</p>
        <p>Purchasers, who may be only a natural person, of the digital content (being private individuals only) are entitled to use the digital content exclusively for their personal use and for the purposes other than economic or business benefit, whether direct or indirect, unless the license conditions stated otherwise. The purchasers are further not entitled to copy or otherwise reproduce, make copies of or extracts from and otherwise handle the purchased digital content contrary to copyright law or other legal regulations or licensing terms of the product. Access to the digital content may be denied or license can be deactivated if the digital content has been obtained as a result of illegal activity.</p>
        <h5>This provision of the General Business Terms is used also for digital content that Eurocom-Europe.com provided to the Purchaser as a gift.</h5>
        <ol className="lower-latin">
            <li>Withdrawal</li>
            <li>Consumer</li>          
        </ol>
        <h5>Consumers are entitled to withdraw from the agreement within a period of 14 days. The period stated in the first sentence commences on the day the agreement is executed, this being the day of:</h5>
        <ol className="lower-latin">
            <li>receiving the goods (purchase agreements);</li>
            <li>receiving the last delivery (agreements for the purchase of several types of goods / delivery of several parts); or</li>
            <li>receiving the first delivery (ongoing contracts).</li>
        </ol>
        <p>Consumers may withdraw from the agreement by completing and submitting the template form; once submitted, the completed form is confirmed as received by Eurocom-Europe.com in writing without undue delay.</p>     
        <h5>Consumers may withdraw from the agreement also by sending a notice of withdrawal to:</h5>       
        <p>GOLDMAX SERVICES s.r.o.<br />Na Sídlišti 206, Lutín 783 49</p>
        <p>If withdrawing from the agreement, the consumer is to return to Eurocom-Europe.com the goods delivered by Eurocom-Europe.com and do so at consumer’s cost without undue delay, however, no later than within 14 days after withdrawing from the agreement.</p>
        <p>The goods are to be returned complete, i.e. along with the delivered accessories and complete documentation, undamaged, clean, in the original packaging (if possible) and in the condition and value in which the goods have been received by the purchaser. Any fuel tanks returned as part of the returned goods must be returned empty.</p>
        <p>Agreements for the purchase of consumables (cosmetics, drugstore items, etc.) may be withdrawn only if the consumables in question are returned by the purchaser undamaged, unused and in the original packaging.</p>
        <p>Consumers deciding to withdraw from the agreement in the given period are recommended by Eurocom-Europe.com to send the goods to Eurocom-Europe.com address along with a cover letter incl. the reason for withdrawal (not necessary), number of purchase document and number of bank account so that the case can be addressed without delay.</p>
        <p>Consumers are liable to Eurocom-Europe.com for any decrease in value of the goods caused by handling the goods other than as required by the nature and characteristics of the goods.</p>
        <p>Consumers withdrawing from the agreement are refunded the entire amount paid under the agreement whereby this amount is refunded by Eurocom-Europe.com without undue delay, however, no later than within 14 days following the withdrawal, and with the use of the same method of payment as agreed in the agreement.</p>
        <p>Consumers withdrawing from the agreement may as well be refunded the entire amount paid only after the goods are returned to Eurocom-Europe.com or after the consumers prove that the goods have been sent to Eurocom-Europe.com.</p>
        <p>Consumers acknowledge that if the goods are delivered along with any gifts, Eurocom-Europe.com and the purchaser enter into a contract of donation on the condition that such contract of donation terminates as soon as the consumer enjoys its right and withdraws from the purchase agreement within a period of 14 days whereby the consumer must return the goods in question along with the gifts and everything obtained in relation thereto; if not returned, the same will be considered unjust enrichment. If the profits acquired by unjust enrichment cannot be returned, Eurocom-Europe.com is entitled to claim monetary compensation in the amount of ordinary price.</p>
       
        <h4>10. Other</h4>
        <h5>If the product in question cannot be returned in the condition originally received by the purchaser, the agreement cannot be withdrawn or delivery of a new product claimed. This does not apply to cases where:</h5>
        <ol className="lower-latin">
            <li>The condition changed due to the inspection of the product for defects.</li>
            <li>The product was used by the purchaser before the product was found defective.</li>
            <li>The product cannot be returned in the original condition for reasons other than beyond control (wilful act or neglect) of the purchaser. OR</li>
            <li>The product was sold by the purchaser before the product was found defective, consumed by the purchaser or altered by the purchaser in ordinary use. If only a part of the product was used, consumed or altered, the purchaser returns to Eurocom-Europe.com everything that can be returned and compensates Eurocom-Europe.com up to the amount of benefit received from using the product.</li>
        </ol>
        <p>Purchasers failing to report the defect in due course are not entitled to withdraw from the agreement.</p>
      
        <h4>11. Personal Data Protection and Security</h4>      
        <p>The seller represents and warrants that all personal data are confidential and such will be used only for the performance of the agreement entered into with the purchaser and for the marketing purposes of the seller (incl. marketing activities performed together with the contractual partners of the seller). The personal data will not be published or disclosed to third parties, with the exception of cases where such disclosure is necessary for the distribution of and payment for the ordered goods (name, account number, shipping address) or for special marketing purposes. In handling the personal data, the purchaser proceeds without detriment to the rights of the entity disclosing the personal data, namely the right to human dignity, and cares for the protection of the private and personal life of the same from unauthorised intervention. The personal data disclosed willingly by the purchaser for the purposes of placing the order and marketing are collected, processed and stored in compliance with law. The purchaser gives consent to the seller to collect and process the disclosed personal data for the performance of the purchase agreement in question and for the marketing purposes of the seller. The consent is granted until revoked in writing by sending the notice of revocation to GOLDMAX SERVICES s.r.o., Na Sídlišti 206, Lutín 783 49. or submitting the notice in electronic form, namely by using the email.</p>
        <p>Customers making a claim with Eurocom-Europe.com are required to provide their full name, address, telephone number and signature (digital signature) whereby such disclosed personal data are processed exclusively for the purposes of handling the claim and in compliance with law.</p>
        <p>Purchasers are entitled to access and edit their personal data, request explanation and removal of mistakes as well as exercise other rights pertaining thereto.</p>
        <p>In obtaining the consent to personal data processing, Eurocom-Europe.com may use cookies in compliance with Directive 95/46/EC (aka Cookie Directive) in order to make the provision of information services easier whereby Eurocom-Europe.com makes sure that the users are aware of the data and information stored in their end devices. The terms and conditions for the use of cookies are available here. The users may prevent the cookies from being stored in their end devices, for instance, by running the anonymous browsing function.</p>
        <p>In order to prevent criminality and minimise the damage, Eurocom-Europe.com reserves the right to reject an order placed by the purchaser from a blocked IP address listed on a blacklist. Purchasers encountering problems with placing their order may contact Eurocom-Europe.com by completing and submitting the contact form or contacting the call centre.</p>
      
        <h4>12. Business Hours</h4>      
        <p>Orders placed in the Eurocom-Europe.com e-shop are accepted 24 hours a day, seven days a week.</p>
        <p>In the event of an information systems failure or force majeure, Eurocom-Europe.com assumes no liability for non-observance of the stipulated business hours.</p>
      
        <h1>IV. Prices</h1>        
        <p>The prices are contract prices. Prices quoted online in the e-shop are always up to date and valid. Prices quoted for individual products are final, that is, incl. VAT and other taxes and fees (if any) payable by the consumer for a product in question (excl. shipping fees, collection fees and cost of distance communication shown in the shopping cart in the amount depending on the option selected by the purchaser).</p>
        <p>Special promotion prices are valid either until stocks are exhausted (with displayed information as to the number of items in question offered for the special promotion price) or for a limited period.</p>
        <p>The “original price” refers to the price at which the goods/services/licenses in question were originally offered in Eurocom-Europe.com e-shop or the manufacturer’s suggested retail price, excluding any bonuses, promotions, special offers or other discounts.  They will always be displayed alongside the selling price, which better reflects the product’s market price level.</p>
        <p>The purchaser acknowledges that Eurocom-Europe.com and the purchaser need necessarily not enter into the agreement, especially if the goods are ordered by the purchaser for a price mistakenly quoted on the website due to the internal Eurocom-Europe.com system error of which the purchaser is informed by Eurocom-Europe.com.</p>
        <p>Eurocom-Europe.com reserves the right to declare the purchase agreement void in case of misused personal data, misused payment card, etc. or with reference to the intervention of an administrative or court authority of which the purchaser is informed by Eurocom-Europe.com. The purchaser acknowledges that the purchase price cannot be entered into as valid in the aforementioned cases.</p>
   
        <h1>V. Orders</h1>        
        <h5>The price is stated on the order and in the text message confirming that the order has been placed. Orders can be placed as follows:</h5>
        <ol className="lower-latin">
            <li>in the e-shop operated by Eurocom-Europe.com (e-shop); or</li>
            <li>by email sent to info@eurocom-europe.com.</li>            
        </ol>
        <p>Eurocom-Europe.com recommends placing the orders in the e-shop by logging into the purchaser’s user account registered with Eurocom-Europe.com. If using public access, Eurocom-Europe.com further recommends logging out after placing the order.</p>
        <p>Information about the exact shipping time is included in the email letter sent to the purchaser. The shipping period and fees depend on the option selected by the purchaser in the second step of placing the order.</p>
        <h4>1. Payments</h4>   
        <h5>Eurocom-Europe.com accepts the following for methods of payment</h5>
        <ol className="lower-latin">
            <li>wire transfer;</li>            
        </ol>
        <p>Unless paid in full and received, the goods remain the property of Eurocom-Europe.com. The risk of damage passes to the purchaser upon receipt.</p>
        <h5>Once the order is placed, the billing details of the purchaser cannot be edited.</h5>
        <ol className="lower-latin">
            <li>Delivery</li>
            <li>Options</li>
        </ol>
        <p>Eurocom-Europe.com delivers the goods either in co-operation with a shipping company or electronically (digital content).</p>
        <p>In the case of force majeure or disruptions in information systems, Eurocom-Europe.com assumes no liability for late delivery of goods.</p>
        <p>Delivery of goods is limited by public holidays in the place of delivery and the Czech Republic.</p>

        <h4>2. Other</h4>   
        <p>Goods purchased with a 0% VAT rate by purchasers-legal entities being VAT payers at the moment of purchase will be delivered only to the governing body of the legal entity in question or to the person authorised in the submitted authenticated power of attorney. Goods purchased with a 0% VAT rate by purchasers-freelancers being VAT payers at the moment of purchase will be delivered to the purchaser-freelancer in question only against a valid ID document (ID card or passport).</p>
        <p>Goods purchased by purchasers being VAT payers at the moment of purchase and invoiced with a 0% VAT rate will be obligatorily delivered to the central office or business premises registered in the Commercial Register, Register of Trade or a similar register.</p>
        <p>When receiving the goods from the shipping company, the purchaser – along with the shipping company representative – is to properly and thoroughly inspect the delivery (namely the number of packages, undamaged sealing tape bearing the company logo and undamaged packaging) as per the enclosed delivery note. The purchaser may refuse to accept the delivery shipped contrary to the purchase agreement, for instance with reference to incomplete or damaged delivery. If accepting the damaged delivery from the shipping company, the purchaser is to describe the damage in the delivery note of the shipping company.</p>
        <p>Incomplete or damaged deliveries must be immediately reported by email and the damage be described in the report on damage executed with the shipping company representatives and sent to Eurocom-Europe.com by fax, email or post without undue delay. Any subsequent claims of incomplete delivery or damaged packaging do not limit the purchaser’s right to claim the guarantee; yet, they enable Eurocom-Europe.com to prove that the same do not represent a conflict with the purchase agreement.</p>
        <h4>3. Guarantee</h4>   
        <p>The guarantee terms and conditions are governed by the Eurocom-Europe.com Claims Code and relevant law. In general, the certificate of guarantee is replaced by the purchase document (cf Claims Code).</p>

        <h1>VI. Final Provisions</h1>  
        <p>The United Nations Convention on Contracts for the International Sale of Goods does not apply.</p>
        <p>Any disputes between Eurocom-Europe.com and the Purchaser they can settle their dispute out-of-court through an Alternative Dispute Resolution or Online Dispute Resolution (ADR / ODR) procedure. In this case the Purchaser can contact the ODR entity. Eurocom-Europe.com recommends the Purchaser to use first contact Eurocom-Europe.com to resolve the situation.</p>
        <p>The agreement is entered into in the English language. If the agreement is required to be translated for the purchaser into another language, the English version prevails in case of discrepancies between the two language versions.</p>
        <p>These General Business Terms and Conditions and parts hereto come into force and effect on 18.9.2016, repealing the previous versions.</p>
    </div>
}