import React from 'react';
import SEO from './SEO';

export const Cooperation = () => {
    return (
        <div style={{ textAlign: 'justify' }}>
            {SEO({ title: "Cooperation | EUROCOM | Goldmax", description: "High-end custom laptops. Flexible and unique solution." })}
            <h1>Cooperation</h1>

            <p>We love technology, and we do it with passion – for specifications, for performance, for the sake of creating systems that are unique and upgradeable! Systems that people use for a long time and love, unlike other products you may sell.</p>

            <p style={{ marginBottom: '2px' }}><b>Why Become a Dealer?</b></p>
            <p style={{ marginBottom: '8px' }}>Eurocom can help you make money. Yes, you can make money selling hardware – provided that you sell Eurocom GPU- and CPU-upgradeable long lifespan hardware.</p>
            <p style={{ marginBottom: '8px' }}>No, you won’t make money selling disposable hardware. Selling commodity products like disposable hardware means heavy competition with others who are also selling disposable goods.</p>
            <p>You are selling commodity products due to a lack of knowledge, skills, and innovation. You have lost leadership in the market. How do you regain that leadership? By becoming a product expert and selling products that are long-lasting, customizable, and upgradeable.</p>
          
            <ul>
                <li>Innovative CPU and GPU upgradeable laptops (sell laptops and upgrades).</li>
                <li>Customized configurations (no price comparison).</li>
                <li>Dealing directly with manufacturer, not distributor (only company on marketplace where the user can go directly to the manufacturer).</li>
                <li>We listen to your feedback and can make changes to our products, if needed.</li>
                <li>You have the ability to increase your margins (check out the Dealer Programs).</li>
                <li>Can upsell client to higher configuration (future-proofing).</li>
                <li>Can sell them a system without a price comparison of other vendors on the marketplace.</li>
                <li>Opportunity Incentive Registration Program can increase margins.</li>                
            </ul>

            <p style={{ marginBottom: '8px' }}>Your Dealership can be a sales leader and increase profits by selling technology that is high-end, innovative, solid, unique, and upgradeable. We understand that you are faced with challenges in today’s market: time constraints, a flood of complex details, lower profit margins, and the shift in the market to online end-user consumerism. It’s a changing landscape.</p>
            <p>We want to enable your communication with the right audience, so we decided to create resources for you about Eurocom’s laptops (check out the Resources section). This makes it possible for you to access information when you need it and become a Eurocom Product Expert. Armed with knowledge, your business development approach can be more proactive, and you can introduce Eurocom’s technology to your clients with confidence.</p>

            <p style={{ marginBottom: '2px', marginTop: '12px' }}><b>Opportunity Incentive Registration Program</b></p>
            <p>Designed to provide additional margins when selling to specific (approved) accounts. Under this program, Eurocom guarantees full protection and special pricing for the account(s) for which you are pre-approved.</p>

            <p style={{ marginBottom: '2px', marginTop: '12px' }}><b>Sales Reward Program</b></p>
            <p>Designed to reward individual Sales Rep performance, based on submitted and approved opportunities. This is in addition to the Opportunity Incentive Registration Program so Sales Reps can personally see the fruits of their labors rewarded.</p>

            <p style={{ marginBottom: '2px', marginTop: '12px' }}><b>Frequent Dealer Demo Program</b></p>
            <p style={{ marginBottom: '8px' }}>Producing Dealers need demos – this is an opportunity to be eligible for a demo model on a quarterly basis without any restrictions</p>
            <p>When you become a Eurocom Dealer, you can purchase a Dealer Demo model at a discounted rate (20% off). You also receive login credentials that direct you to your own area for discounted quotes with the ability to order directly online. If you ever need help with your sales, you can contact us and we are more than happy to work with you. As well, if someone contacts us, but they want to go through a Dealer, we will direct them to their nearest desired location.</p>

            <p style={{ marginBottom: '2px', marginTop: '12px' }}><b>Bid Assistance Program</b></p>
            <p>If you come across any bid for a specific product and it allows you to provide an alternative, equivalent solution, we will be happy to offer you special pricing on Eurocom products and help you win the bid by offering high-end products.</p>

            <p style={{ marginBottom: '2px', marginTop: '12px' }}><b>Eurocom News Program/Joint PR</b></p>
            <p>To showcase successes, we will write a news release about you and Eurocom’s equipment (mentions will include Sales Reps, Dealership, and clients).</p>

            <p style={{ marginBottom: '2px', marginTop: '12px' }}><b>Video/Product Review Program</b></p>
            <p>If you create a video about Eurocom’s equipment that is useful as an additional resource, we will promote it on our website and add it to our resources with full credit to you and your Dealership.</p>

            <p style={{ marginBottom: '2px', marginTop: '12px' }}><b>Training Program</b></p>
            <p>If you need additional training about Eurocom’s technology and models, we will be happy to arrange sessions.</p>

            <p style={{ marginBottom: '2px', marginTop: '12px' }}><b>CAGE Code: Eurocom has an active CAGE code: 3AF79</b></p>
            <p style={{ marginBottom: '8px' }}>WHAT IS A CAGE CODE? A CAGE Code is a five (5) position code that identifies companies doing or wishing to do business with the Federal Government. The format of the code requires that the first and fifth position be numeric, while the second, third and fourth may be any mixture of alpha/numeric characters, excluding I and O. All positions are non-significant.</p>
            <p>WHY DO I NEED A CAGE CODE? The code is used to support a variety of mechanized systems throughout the government. The code provides for a standardized method of identifying a given facility at a specific location. The code may be used for a Facility Clearance, a Pre-Award survey, automated Bidders Lists, pay processes, source of supply, etc. In some cases, prime contractors may require their sub-contractors to have a CAGE Code also.</p>

            <p style={{ marginBottom: '2px', marginTop: '12px' }}><b>Comparison Charts and Resources</b></p>
            <p>We’re creating resources to help you learn more about Eurocom’s products. If you’d like additional information, let us know by sending an email to sales@eurocom-europe.com with the Subject Line: Additional Resources Requested</p>
        </div>
    );
};