import React, { useState } from 'react';
import { DynamicForm } from './DynamicForm';
import SEO from './SEO';

const formSubmitBtnName = 'SEND';
const dialogHeader = 'Test demo request';
const successMessage = 'Your request has been sent, we will contact you within the next business day.';
const errorMessage = 'Your request failed to sent, please contact us by e-mail or phone.';
const fields = [
    { prop: 'name', label: 'Full name', placeholder: '', type: 'text', size: 6, validation: 'Please enter your name.', validationtype: 'required' },
    { prop: 'firm', label: 'Company name', placeholder: '', type: 'text', size: 6, validation: '', validationtype: '' },
    { prop: 'email', label: 'E-mail', placeholder: '', type: 'email', size: 6, validation: 'Please enter your email address.', validationtype: 'email' },
    { prop: 'phone', label: 'Phone number', placeholder: '', type: 'text', size: 6, validation: 'Please enter your phone number.', validationtype: 'phone' },
    { prop: 'count', label: 'Planned number of new laptops', placeholder: '', type: 'number', size: 6, validation: 'Please enter count.', validationtype: 'required' },
    { prop: 'price', label: 'Planned budget', placeholder: '', type: 'number', size: 6, validation: 'Please enter budget.', validationtype: 'required' },   
    { prop: 'message', label: 'Your message', placeholder: '', type: 'textarea', size: 12, validation: '', validationtype: '' }
];
let dynamicObject = {};
fields.map(item => dynamicObject[item.prop] = '');

export const TestLend = () => {   
    const [object, setObject] = useState(dynamicObject);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogBody, setDialogBody] = useState('');

    const handleObjectChange = e => {
        const { id, value } = e.target;
        setObject(prevObj => ({ ...prevObj, [id]: value }));
    };
    const onSubmit = () => {
        setLoading(true);
        sendFomrAsync();
    };
    const onCloseDialog = () => {
        setDialogOpen(false);
    }

    const sendFomrAsync = async () => {       
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };
        const response = await fetch('form?id=4', requestOptions);
        if (response.ok) {
            setDialogBody(successMessage);
            setObject(dynamicObject);
        }
        else {
            setDialogBody(errorMessage);
        }
        setLoading(false);
        setDialogOpen(true);
    }

    return (
        <>
            {SEO({ title: "Company testing | EUROCOM | Goldmax", description: "High-end custom laptops. Flexible and unique solution." })}
            <h1>Test Demo Request</h1>
            <p>Are you a company owner, ICT Supplier or Public Procurement Tender participant? Before you say YES, let us offer you a VERY SPECIAL option to TEST EUROCOM DEMO before the actual purchase.</p>
            <p>We understand that executing purchase of 10, 50 or 200pcs of laptops is not Easy a Should be based on Real life experience with the Product. We sell Ferrari like notebooks - the fastest, expensive and the most innovative Desktop Replacement solution so we invented the FREE DEMO REQUEST TESTING. Please let us know the important:</p>
            <ul>
                <li>Pick a laptop model with actual specification you need. Not sure? We can help!</li>
                <li>Let us know how many units you need and when do you plan to purchase them.</li>
                <li>Send us your company profile and describe how you usually work.</li>
                <li>After we recieve your TEST DEMO REQUEST we will get back to you with approval.</li>
                <li>You Pay Full Price of the laptop (Covers theft and damage) and we build your laptop DEMO for you and ship to your location. Call this Security Deposit.</li>
                <li>After TEST Period is over, its time to decide what would you like to do. You can either return laptop and ask for Full Refund or keep the laptop, change specs etc.</li>
            </ul>
            <p>The only Fee applied for the TEST DEMO REQUEST is shipping Fee dependant on your location within EU. Regular TEST DEMO period is usually 7 days. Before we Payout Security Deposit back to you (Price of the laptop that covers possible Theft and Damage) We need to fully inspect returned laptop and check its overal conditions put on the record. Any harm done to the returned laptop or a TEST DEMO except „common usage“ will be deducted from the Security Deposit but this not usually the case we face.</p>

            <DynamicForm fields={fields} object={object} submitBtnName={formSubmitBtnName} onChangeObject={handleObjectChange} onSubmitForm={onSubmit} loading={loading} dialogHeader={dialogHeader} dialogBody={dialogBody} dialogOpen={dialogOpen} onCloseDialog={onCloseDialog} />

            <p>Contact us today at <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a> or call us <a href="tel:+420724583823">+420 724 583 823</a> for more details and ask how we can help you succeed in your ICT supplier chain.</p>
        </>
    );
};