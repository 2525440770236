import React, { Component } from 'react';
import Loader from './Loader';
import { DialogInfo } from './DialogInfo';
import { Route } from 'react-router-dom';
import { ErrorNotFound } from './ErrorNotFound';
import { AppStateContext } from './AppStateContext';
import { isEmpty, validateEmail, validatePhone } from '../helpers/validator';
import SEO from './SEO';

export class QuotationConfig extends Component {
    static displayName = QuotationConfig.name;
    static contextType = AppStateContext;

    constructor(props) {
        super(props);
        this.state = { model: null, loading: false, isOpen: false, message: "", validFirstName: true, validLastName: true, validEmail: true, validPhone: true, inquiryModel: null };        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.firstname = this.firstname.bind(this);
        this.lastname = this.lastname.bind(this);
        this.company = this.company.bind(this);
        this.email = this.email.bind(this);
        this.phone = this.phone.bind(this);
        //this.subject = this.subject.bind(this);
        this.message = this.message.bind(this);
        window.onpopstate = this.onBackButtonEvent.bind(this);
                
        if (this.props.location.state) {
            this.state.model = this.props.location.state.model;
        }
    }

    componentDidMount() {
        this.getInquryAsync();
    }

    onBackButtonEvent = (e) => {
        window.onpopstate = () => { };
        this.props.history.push('/configurator/' + this.props.location.state.model.notebook.urlName,
            {
                model: this.props.location.state.model,
                config: this.props.location.state.config,
                summary: this.props.location.state.summary,
                count: this.props.location.state.count
            });
    }
    handleSubmit() {
        var validFirstName = !isEmpty(this.state.inquiryModel.customer.firstName);
        var validLastName = !isEmpty(this.state.inquiryModel.customer.lastName);
        var validEmail = validateEmail(this.state.inquiryModel.customer.email);
        var validPhone = validatePhone(this.state.inquiryModel.customer.phone);
        this.setState({ validFirstName, validLastName, validEmail, validPhone });

        if (validFirstName && validLastName && validEmail && validPhone) {
            this.setState({ loading: true });
            this.sendAsync(this.state.inquiryModel);
        } 
    }
    toggleModal = () => {       
        this.props.history.replace('/configurator/' + this.props.location.state.model.notebook.urlName,
            {
                model: this.props.location.state.model,
                config: this.props.location.state.config,
                summary: this.props.location.state.summary,
                count: this.props.location.state.count
            });
    }   
    firstname(e) {
        const tempModel = this.state.inquiryModel;
        tempModel.customer.firstName = e.target.value;
        this.setState({ inquiryModel: tempModel });
    }
    lastname(e) {
        const tempModel = this.state.inquiryModel;
        tempModel.customer.lastName = e.target.value;
        this.setState({ inquiryModel: tempModel });
    }
    company(e) {
        const tempModel = this.state.inquiryModel;
        tempModel.customer.companyName = e.target.value;
        this.setState({ inquiryModel: tempModel });
    }
    email(e) {
        const tempModel = this.state.inquiryModel;
        tempModel.customer.email = e.target.value;
        this.setState({ inquiryModel: tempModel });
    }
    phone(e) {
        const tempModel = this.state.inquiryModel;
        tempModel.customer.phone = e.target.value;
        this.setState({ inquiryModel: tempModel });
    }
    //subject(e) {
    //    const tempModel = this.state.inquiryModel;
    //    tempModel.emailSubject = e.target.value;
    //    this.setState({ inquiryModel: tempModel });
    //}
    message(e) {
        const tempModel = this.state.inquiryModel;
        tempModel.emailMessage = e.target.value;
        this.setState({ inquiryModel: tempModel });
    }

    
    render() {
        if (typeof this.props.location.state == 'undefined') {
            return <Route path="*" component={ErrorNotFound} />
        }
        let loader = this.state.loading && <Loader loading={this.state.loading} />

        if (this.state.inquiryModel !== null)
            return (
                <React.Fragment >
                    {this.state.model && SEO({
                        title: "Configuration quotation | " + this.state.model.notebook.seoTitleEn,
                        description: this.state.model.notebook.seoDescriptionEn,
                        siteUrl: this.state.model.notebook.urlName,
                        sourceImgUrl: this.state.model.notebook.notebookImages[0].link
                    })}
                    {loader}
                    <DialogInfo
                        header="Request for a Quote" show={this.state.isOpen}
                        onClose={this.toggleModal}>
                        {this.state.message}
                    </DialogInfo>
                    <h4 style={{ fontSize: '1.15em', fontWeight: 400, marginBottom: 0 }}>Request for a Quote</h4>                   
                    <h4 style={{ paddingTop: '2px' }}>{this.state.model && this.state.model.notebook.name}</h4>
                    <p style={{ paddingTop: "12px" }}>This is a Request for a Quote of EUROCOM Laptop that you have customized in the previous step. Please fill in contact form below so we can get back to you.</p>
                    <div style={{ paddingTop: "16px" }} class="form-row">
                        <div class="col-md-4 mb-3">
                            <label for="firstname">First name</label>
                            <input type="text" class={this.state.validFirstName ? "form-control" : "form-control is-invalid"} id="firstname" placeholder="" required
                                onChange={this.firstname} value={this.state.inquiryModel.customer.firstName == "" ? "" : this.state.inquiryModel.customer.firstName} />
                            <div class="invalid-feedback">
                                Please enter your first name.
                        </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="lastname">Last name</label>
                            <input type="text" class={this.state.validLastName ? "form-control" : "form-control is-invalid"} id="lastname" placeholder="" required
                                onChange={this.lastname} value={this.state.inquiryModel.customer.lastName == "" ? "" : this.state.inquiryModel.customer.lastName} />
                            <div class="invalid-feedback">
                                Please enter your last name.
                        </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="company">Company name</label>
                            <input type="text" class="form-control" id="company" placeholder="Company name (optional)" required
                                onChange={this.company} value={this.state.inquiryModel.customer.companyName == "" ? "" : this.state.inquiryModel.customer.companyName} />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6 mb-3">
                            <label for="email">E-mail</label>
                            <input type="email" class={this.state.validEmail ? "form-control" : "form-control is-invalid"} id="email" placeholder="" required
                                onChange={this.email} value={this.state.inquiryModel.customer.email == "" ? "" : this.state.inquiryModel.customer.email} />
                            <div class="invalid-feedback">
                                Please enter your email address.
                        </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="phone">Phone number</label>
                            <input type="text" class={this.state.validPhone ? "form-control" : "form-control is-invalid"} id="phone" placeholder="" required
                                onChange={this.phone} value={this.state.inquiryModel.customer.phone == "" ? "" : this.state.inquiryModel.customer.phone} />
                            <div class="invalid-feedback">
                                Please enter your phone number.
                        </div>
                        </div>
                    </div>
                    {
                        //<div class="form-row">
                        //    <div class="col-md-12 mb-3">
                        //        <label for="subject">Předmět zprávy</label>
                        //        <input type="text" class="form-control" id="subject" placeholder="Předmět" required
                        //            onChange={this.subject} value={this.state.inquiryModel.emailSubject} />
                        //    </div>
                        //</div>
                    }
                    <div class="form-row">
                        <div class="col-md-12 mb-3">
                            <label for="message">Your message</label>
                            <textarea class="form-control" id="message" placeholder="" rows="4"
                                onChange={this.message} value={this.state.inquiryModel.emailMessage} />
                        </div>
                    </div>
                    <div className="col-sm-12 text-center">
                        <button className="submit-button rounded" onClick={this.handleSubmit}>SEND</button>
                    </div>
                    <div style={{ height: '48px' }}></div>
                </React.Fragment>
            );
        else
            return (
                <React.Fragment>
                </React.Fragment>
                )
    }

    async sendAsync(model) {
        let { currency } = this.context[0];
        if (currency.code === "EUR") { model.isEUR = true; }
        else { model.isEUR = false; }
        model.exchangeRate = currency.exchangeRate;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        };
        const response = await fetch('order/quotationconfig', requestOptions);
        var message = "";
        if (response.ok) {
            message = "Your Quote was succesfully delivered to EUROCOM Team."
        }
        else {
            message = "Your request failed to sent, please contact us by e-mail or phone."
        }
        this.setState({ loading: false, isOpen: true, message });
    }

    async getInquryAsync() {
        const requestOptions = { method: 'GET', 'Content-Type': 'application/json' };
        const response = await fetch('order/', requestOptions);
        const data = await response.json();
        data.customer.email = "";
        data.customer.phone = "";
        data.emailSubject = this.state.model.notebook.name; //+ " - nezávazná poptávka cenové nabídky konfigurace";
        data.emailMessage = "Hello,";
        data.configuration_VMs.push(this.state.model);
        this.setState({ inquiryModel: data, loading: false });
    }

 

}
