import React, { useState } from 'react';
import { DynamicForm } from './DynamicForm';
import SEO from './SEO';

const formSubmitBtnName = 'SEND';
const dialogHeader = 'Price quote request';
const successMessage = 'Your request has been sent, we will contact you within the next business day.';
const errorMessage = 'Your request failed to sent, please contact us by e-mail or phone.';
const fields = [
    { prop: 'purpose', label: 'What is the purpose of the laptop?', placeholder: 'What SW/Apps you often use?', type: 'text', size: 12, validation: 'Please enter purpose.', validationtype: 'required' },
    { prop: 'count', label: 'How many laptops do you need for your or your company?', placeholder: '', type: 'text', size: 12, validation: 'Please enter count.', validationtype: 'required' },
    { prop: 'req', label: 'What are basic technical requirements and priorities?', placeholder: '', type: 'text', size: 12, validation: '', validationtype: '' },
    { prop: 'name', label: 'Full name', placeholder: '', type: 'text', size: 6, validation: 'Please enter your name.', validationtype: 'required' },
    { prop: 'firm', label: 'Company name', placeholder: '', type: 'text', size: 6, validation: '', validationtype: '' },
    { prop: 'email', label: 'E-mail', placeholder: '', type: 'email', size: 6, validation: 'Please enter your email address.', validationtype: 'email' },
    { prop: 'phone', label: 'Phone number', placeholder: '', type: 'text', size: 6, validation: 'Please enter your phone number.', validationtype: 'phone' },
];
let dynamicObject = {};
fields.map(item => dynamicObject[item.prop] = '');

export const QuotationRequest = () => {
    const [object, setObject] = useState(dynamicObject);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogBody, setDialogBody] = useState('');    

    const handleObjectChange = e => {
        const { id, value } = e.target;
        setObject(prevObj => ({ ...prevObj, [id]: value }));
    };
    const onSubmit = () => {
        setLoading(true);
        sendFomrAsync();
    };
    const onCloseDialog = () => {
        setDialogOpen(false);
    }

    const sendFomrAsync = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };
        const response = await fetch('form?id=6', requestOptions);
        if (response.ok) {
            setDialogBody(successMessage);
            setObject(dynamicObject);
        }
        else {
            setDialogBody(errorMessage);
        }
        setLoading(false);
        setDialogOpen(true);
    }

    return (
        <>
            {SEO({ title: "Price inquiry | EUROCOM | Goldmax", description: "High-end custom laptops. Flexible and unique solution." })}
            <h1>Price Quote Request</h1>
                       
            <p>Are you interested in EUROCOM products but need a better price? Don’t hesitate to ask, we are here to help. If you need multiple laptops for your big projects, you will get special prices. We also offer clevo MXM GPU upgrade, technical support for RMA ouf of warranty, CLEVO spare parts and LCD replacements. Please explain following questions:</p>
            
            <DynamicForm fields={fields} object={object} submitBtnName={formSubmitBtnName} onChangeObject={handleObjectChange} onSubmitForm={onSubmit} loading={loading} dialogHeader={dialogHeader} dialogBody={dialogBody} dialogOpen={dialogOpen} onCloseDialog={onCloseDialog} />

            <p>Contact us today at <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a> or call us <a href="tel:+420724583823">+420 724 583 823</a> for more details and ask how we can help you succeed in your ICT supplier chain.</p>
        </>
    );
};