import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { AppStateContext } from './AppStateContext'
import './Footer.css';

export const Footer = () => {  
    const history = useHistory();
    const [appState, setAppState] = useContext(AppStateContext);
    return <footer className={appState.footer ? "footer-visible" : "footer-invisible"}>
       <div className="footer-container">
            <div className="footer-third-container">
                <h3>Partner sales</h3>
                <p><a target="_blank" href="https://www.czc.cz/notebooky/eurocom/produkty">CZC.CZ</a></p>
                <p><a target="_blank" href="https://www.mall.cz/znacka/eurocom?entityRecognition=1&s=eurocom&_fid=nqfs">MALL.CZ</a></p>                
            </div>
            <div className="footer-third-container">
                <h3>General</h3>
                <p onClick={() => { history.push('/terms'); window.scrollTo(0, 0) }}>Terms and Conditions</p>
                <p onClick={() => { history.push('/gdpr'); window.scrollTo(0, 0) }}>GDPR Privacy Statement</p>                
            </div>
            <div className="footer-third-container">
                <h3>Contact</h3>
                <p onClick={() => { history.push('/contact'); window.scrollTo(0, 0) }}>EUROCOM Europe</p>
                <p onClick={() => { history.push('/contact'); window.scrollTo(0, 0) }}>GOLDMAX SERVICES s.r.o</p>
                <p onClick={() => { history.push('/contact'); window.scrollTo(0, 0) }}>Ing. Ondřej Káhn, CEO</p>
                <p><a href="tel:+420724583823">+420 724 583 823</a></p>
                <p><a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a></p>
            </div>
            <hr className="footer-hr" />
            <div className="footer-end-container">
                <div className="copy-mobile-invisible">©2020 EUROCOM EUROPE All rights reserved.</div>
                <div className="social-icon-container">
                    <img src="icons/facebook-icon.svg" onClick={() => window.open("https://www.facebook.com/Goldmax.cz?ref=hl", "_blank")} />
                    <img src="icons/instagram-icon.svg" onClick={() => window.open("https://www.instagram.com/goldmaxcz/", "_blank")} />
                    <img src="icons/youtube-icon.svg" onClick={() => window.open("https://www.youtube.com/user/EurocomTechnology", "_blank")} />
                </div>
                <div className="copy-mobile-visible">©2021 EUROCOM EUROPE</div>
                <div className="created-container">Created by...</div>
            </div>
            

            
            </div>
    </footer>

}