import './custom.css'
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Configurator } from './components/Configurator';
import { NotebooksOverview } from './components/NotebooksOverview';
//import { Admin } from './components/Admin'
//import { ANotebookList } from './components/ANotebookList'
//import { ANotebookCreate } from './components/ANotebookCreate';
//import { ANotebookUpdate } from './components/ANotebookUpdate';
//import { ANotebookDuplicate } from './components/ANotebookDuplicate';
//import { ANotebookDetail } from './components/ANotebookDetail';
import { ErrorNotFound } from './components/ErrorNotFound';
import { NotebookDetail } from './components/NotebookDetail';
import { QuotationConfig } from './components/QuotationConfig';
import { ShoppingCart } from './components/ShoppingCart';
//import { AdminVoucher } from './components/AdminVoucher';
//import { AdminCarousel } from './components/AdminCarousel';
//import { AdminCurrency } from './components/AdminCurrency';
//import { AComponent } from './components/AComponent';
//import { ANewsletter } from './components/ANewsletter';
import { GDPR } from './components/GDPR';
import { WhyEurocom } from './components/WhyEurocom';
import { CaseStudies } from './components/CaseStudies';
import { VR } from './components/VR';
import { Students } from './components/Students';
import { MultiGPU } from './components/MultiGPU';
import { Review } from './components/Review';
import { Tenders } from './components/Tenders';
import { Cleaning } from './components/Cleaning';
import { RMA } from './components/RMA';
import { Pickup } from './components/Pickup';
import { Lib } from './components/Lib';
import { Drivers } from './components/Drivers';
import { Eurocom } from './components/Eurocom';
import { Cooperation } from './components/Cooperation';
import { TestLend } from './components/TestLend';
import { QuotationRequest } from './components/QuotationRequest';
import { Upgrade } from './components/Upgrade';
import { Terms } from './components/Terms';
import { authenticationService } from './services/authentication.service';
import { Prepareing } from './components/Prepareing';
import { Contact } from './components/Contact';

export default class App extends Component {
  static displayName = App.name;

    constructor(props) {
        super(props);

        this.state = {
            currentUser: null            
        };
    }
   
    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x })); 
    }    

    render() {
        const { currentUser } = this.state;
        return (
            <Layout>
                <Switch>
                    <Route exact path='/' component={Home} />                    
                    {/*<Route path='/notebooks/:type' component={NotebooksOverview} />*/}
                    <Route path='/notebooks-in-stock' component={NotebooksOverview} />
                    <Route path='/mobile-supercomputers' component={NotebooksOverview} />
                    <Route path='/mobile-workstations' component={NotebooksOverview} />
                    <Route path='/mobile-servers' component={NotebooksOverview} />
                    <Route path='/enterprise-laptops' component={NotebooksOverview} />
                    <Route path='/gaming-laptops' component={NotebooksOverview} />
                    {/*<Route path='/detail/:id' component={NotebookDetail} />*/}
                    {/*<Route path='/configurator' component={Configurator} />*/}

                    <Route path='/quotation-config' component={QuotationConfig} />
                    <Route path='/shoppingcart' component={ShoppingCart} />
                    <Route path='/whyeurocom' component={WhyEurocom} />
                    <Route path='/casestudies' component={CaseStudies} />
                    <Route path='/vr' component={VR} />
                    <Route path='/students' component={Students} />
                    <Route path='/multigpu' component={MultiGPU} />
                    <Route path='/review' component={Review} />
                    <Route path='/tenders' component={Tenders} />
                    <Route path='/cleaning' component={Cleaning} />
                    <Route path='/rma' component={RMA} />
                    <Route path='/pickup' component={Pickup} />
                    <Route path='/lib' component={Lib} />
                    <Route path='/drivers' component={Drivers} />
                    <Route path='/cooperation' component={Cooperation} />
                    <Route path='/eurocom' component={Eurocom} />
                    <Route path='/testlend' component={TestLend} />
                    <Route path='/quotationrequest' component={QuotationRequest} />
                    <Route path='/contact' component={Contact} />
                    <Route path='/upgrade' component={Upgrade} />
                    <Route path='/terms' component={Terms} />
                    <Route path='/gdpr' component={GDPR} />
                    {/*<Route path="/admin/*" component={ErrorNotFound} />
                    <Route path='/admin' component={Admin} />
                    <Route path='/admin-notebook' component={ANotebookList} />
                    <Route path='/admin-notebook-create' component={ANotebookCreate} />
                    <Route path='/admin-notebook-update' component={ANotebookUpdate} />
                    <Route path='/admin-notebook-duplicate' component={ANotebookDuplicate} />
                    <Route path='/admin-notebook-detail' component={ANotebookDetail} />
                    <Route path='/admin-voucher' component={AdminVoucher} />
                    <Route path='/admin-carousel' component={AdminCarousel} />
                    <Route path='/admin-currency' component={AdminCurrency} />
                    <Route path='/admin-component' component={AComponent} />
                    <Route path='/admin-newsletter' component={ANewsletter} />                                       
                    <Route path='/prepareing' component={Prepareing} />*/}

                    {/* Redirect for url addresses with params */}
                    <Redirect from="/notebooks" to={{ pathname: "/notebooks-in-stock", state: { status: 301 } }} />
                    <Redirect from="/notebooks/*" to={{ pathname: "/notebooks-in-stock", state: { status: 301 } }} />
                    <Redirect from="/detail" to={{ pathname: "/notebooks-in-stock", state: { status: 301 } }} />
                    <Redirect from="/detail/*" to={{ pathname: "/notebooks-in-stock", state: { status: 301 } }} />
                    

                    { /* New url for configurator and detail */}
                    <Route path='/configurator/:urlName' component={Configurator} />

                    {/* Redirect for url addresses with params */}
                    <Redirect from="/configurator" to={{ pathname: "/mobile-supercomputers", state: { status: 301 } }} />

                    <Route path='/:urlName' component={NotebookDetail} />

                    <Route path="*" component={ErrorNotFound} />                     
                </Switch>
            </Layout>
        );
    }
}
