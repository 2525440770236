import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import SEO from './SEO';

export const WhyEurocom = () => {
    return (
        <div>
            {SEO({ title: "What clevo reseller | EUROCOM | Goldmax", description: "EUROCOM Premium quality laptop shipping to EUROPE, Germany, France, Poland, UK and Hungaria." })}
            <h1>Do not hesitate with the EUROCOM Technlogy purchase Today! In case you still have more questions here is bunch of rock solid reasons „Why EUROCOM is a perfect mobile solution for you!“</h1>
            <p>8 Rock solid reasons to buy an EUROCOM laptop.</p>
            <p>NOTE: Do you need multiple highly customized and fully upgradable EUROCOM notebooks for your company or government tenders? Please contact us at <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a> to get special pricing, individual warranty and customized specifications according to your needs. GPU MXM upgrades available!</p>
            <p style={{ paddingBottom: '20px', lineHeight: '1.6' }}>
                <Accordion multiple>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>1. What does it mean for a Laptop to be Upgradable? Is it user expandable as well?</span>}>
                        EUROCOM high performance laptops are based on modular technology that allows you to customize the internal components according to specific needs… Most of the EUROCOM laptops and fully configurable and upgradeable (i.e. CPU, GPU, RAM, SSD, HDD, M.2, WIFI, LCD etc.). Eurocom modular laptops can be upgraded with higher-end specifications with more performance after a few months or years from product purchase and most parts are also user upgradable/expandable without compromising manufacturer warranty. EUROCOM laptops are indeed unique!
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>2. About EUROCOM Corporation.</span>}>
                        Since 1989, Eurocom has been a leading developer of long lifespan, fully upgradable Notebooks, High Performance Mobile Workstations and Mobile Servers. We are committed to offering our clients, our suppliers, and our employees a new sense of freedom through innovative technology, ahead of its time, in the most personal way.
                        <br /><br />
                        Throughout its history the company has remained years ahead of the competition by matching current high-end technology with superior customer service to generate an excellent overall experience
                        <br /><br />
                        Many of <b>EUROCOM laptops</b> has been already reviewed and tested by valued European media partners. Feel free to read some of these reviews <a target="_blank" href="https://www.eurocom-europe.cz/review">here</a> or just Google “EUROCOM REVIEW”
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>3. Who is your usual customer, do you have any references?</span>}>
                        Between our clients we have various companies and individuals located on four continents. We have supplied the most advancedgovernment agencies including national Army and Navy forces, the biggest IT manufacturers along with small and midsize bussineses.
                        <ul>
                            <li>Public sector and private firms employing cutting-edge computer technology and servers.</li>
                            <li>Manufacturing production, automotive sector and simulations.</li>
                            <li>Graphic design studios focused on editing high-definition video/ photo editing, Virtual Reality and 3D production.</li>
                            <li>CAx / GIS / PLM in 3D technology development in the field of mechanics and particle flow.</li>
                            <li>IT specialists and programmers providing service and development of corporate / cloud apps.</li>
                            <li>Engineering consultants ensuring the implementation and coordination of construction.</li>
                            <li>Database Administrator , ERP vendors and comprehensive business information systems.</li>
                        </ul>
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>4. How long until delivery? What if I need my laptop ASAP?</span>}>
                        Each laptop is individually assembled according to customer wishes by our specialized technicians. Human resources are essential in the matter of quality and complex product reliability. EUROCOM products are also being stress tested to make sure that every component is rock solid stable even under extraordinary workloads. The entire production procedure takes about 7 days while the expected delivery from payment is usually about 2-3 weeks to any EU country. Please note that international couriers and/or custom procedures are beyond our control therefore we keep every client updated regarding expected delivery.
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>5. What financing options do you offer?</span>}>
                        Please contact us at <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a> for indivudual lease/financing requests.
                        <br />
                        We will be happy to prepare special payment options according to your needs.
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>6. Are EUROCOM products suitable as mobile workstations and servers?</span>}>
                        <b><i>That is exactly what we can do better than any competition out there.</i></b> Engineering on-the Go and Server on the Go solutions are our speciality. The main concept is to simplify the life of engineers, technicians, designers, developers or programmers by making their PC truly mobile with no performance or advanced feature sacrifice. Our Mobile Workstations are designed for long lifespan and high performance computing by the most demanding applications and users. Engineers, CAD/CAM/CAE and 3D designers, architects, animators, graphics professionals, software developers and digital content creators who travel, yet need access to workstation on-the-go computing, are the core beneficiaries of Mobile Workstation technology. With unrivaled power, unsurpassed features and unmatched style, the Mobile Workstation is designed to replace your traditional desktop based workstation computer and allow you to move to the next generation of computing.
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>7. Does EUROCOM develop hardcore gaming notebooks?</span>}>
                        EUROCOM EUROPE Gaming Laptops are designed to help you discover your darkside while enjoying your favourite games.  We have the most powerfull, fully upgradable and highly customizable gaming laptops that will offer you extraordinary overclocking options. You will not find faster and more capable ultimate gaming laptop on the market simply because its EUROCOM who leads the industry.
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>8. GPU MXM UPGRADES, CLEVO spare parts or LCD replacements?</span>}>
                        EUROCOM-EUROPE offers their technical support services to CLEVO notebook laptop owners. Do you need RMA for CLEVO notebook, CLEVO spare parts services or GPU upgrade? MXM GPU are extremely rare and EUROCOM offers GPU upgrade kits for notebooks of all major brands like MSI, Sager, XMG or Alienware MXM GPU. 
                    </AccordionTab>                   
                </Accordion>
            </p>
            <p>
                <p>Contact us today at <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a> or call us <a href="tel:+420724583823">+420 724 583 823</a> for more details and ask how we can help you succeed in your ICT supplier chain.</p>
            </p>
        </div>
    );
};