import React, { useState } from 'react';
import { DynamicForm } from './DynamicForm';
import { useHistory } from "react-router-dom";
import SEO from './SEO';

const formSubmitBtnName = 'SEND';
const dialogHeader = 'Pick-up request';
const successMessage = 'Your request has been sent, we will contact you within the next business day.';
const errorMessage = 'Your request failed to sent, please contact us by e-mail or phone.';
const fields = [
    { prop: 'name', label: 'Full name', placeholder: '', type: 'text', size: 6, validation: 'Please enter your name.', validationtype: 'required' },
    { prop: 'firm', label: 'Company name', placeholder: '', type: 'text', size: 6, validation: '', validationtype: '' },
    { prop: 'email', label: 'E-mail', placeholder: '', type: 'email', size: 6, validation: 'Please enter your email address.', validationtype: 'email' },
    { prop: 'phone', label: 'Phone number', placeholder: '', type: 'text', size: 6, validation: 'Please enter your phone number.', validationtype: 'phone' },
    { prop: 'street', label: 'Street address', placeholder: 'House number and street name', type: 'text', size: 6, validation: 'Please enter your street address.', validationtype: 'required' },
    { prop: 'zipcode', label: 'ZIP', placeholder: '', type: 'number', size: 6, validation: 'Please enter your ZIP.', validationtype: 'required' },
    { prop: 'city', label: 'City', placeholder: '', type: 'text', size: 6, validation: 'Please enter your city.', validationtype: 'required' },
    { prop: 'country', label: 'Country', placeholder: '', type: 'text', size: 6, validation: 'Please enter your country.', validationtype: 'required' },
    { prop: 'date', label: 'Pick-up date', placeholder: 'The next business day', type: 'text', size: 12, validation: '', validationtype: '' }    
];
let dynamicObject = {};
fields.map(item => dynamicObject[item.prop] = '');

export const Pickup = () => {    
    const [object, setObject] = useState(dynamicObject);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogBody, setDialogBody] = useState('');
    const history = useHistory();

    const handleObjectChange = e => {
        const { id, value } = e.target;
        setObject(prevObj => ({ ...prevObj, [id]: value }));
    };
    const onSubmit = () => {
        setLoading(true);
        sendFomrAsync();
    };
    const onCloseDialog = () => {
        setDialogOpen(false);
    }

    const sendFomrAsync = async () => {       
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };
        const response = await fetch('form?id=3', requestOptions);
        if (response.ok) {
            setDialogBody(successMessage);
            setObject(dynamicObject);
        }
        else {
            setDialogBody(errorMessage);
        }
        setLoading(false);
        setDialogOpen(true);
    }

    return (
        <>
            {SEO({ title: "Courier Pickup | EUROCOM | CLEVO", description: "Spare parts clevo order." })}
            <h1>Courier Pick-up Order</h1>
            
            <p>Pokud řešíte jakýkoliv požadavek a dostali jste od nás pokyn k objednávce svozu, vyplňte prosím formulář.</p>
            <p><b>Please note:</b> This form maybe used by customers that already passed through 
                <span className="text-link" onClick={() => history.push('/cleaning')}> CLEANING</span>,
                <span className="text-link" onClick={() => history.push('/rma')}> RMA REQUEST</span>, or
                <span className="text-link" onClick={() => history.push('/upgrade')}> UPGRADE</span>.
            </p>
            <p>We can simpy Pick-up your laptop after both sides are fully informed and has agreed on both Pricing and Range of Requested services.</p>

            <DynamicForm fields={fields} object={object} submitBtnName={formSubmitBtnName} onChangeObject={handleObjectChange} onSubmitForm={onSubmit} loading={loading} dialogHeader={dialogHeader} dialogBody={dialogBody} dialogOpen={dialogOpen} onCloseDialog={onCloseDialog} />

            <p>Contact us today at <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a> or call us <a href="tel:+420724583823">+420 724 583 823</a> for more details and ask how we can help you succeed in your ICT supplier chain.</p>
        </>
    );
};