import React from 'react';
import SEO from './SEO';

export const Drivers = () => {
    return (
        <>
            {SEO({ title: "Clevo drivers | EUROCOM | CLEVO ", description: "High-end custom laptops. Flexible and unique solution." })}
            <h1>Download Drivers</h1>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>Official Channel with Product library and EUROCOM Drivers</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="https://eurocom.com/ec/pdrivers(1)ec" target="_blank" rel="noopener noreferrer">
                    https://eurocom.com/ec/pdrivers(1)ec
                </a>
            </p>

            <p>Contact us today at <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a> or call us <a href="tel:+420724583823">+420 724 583 823</a> for more details and ask how we can help you succeed in your ICT supplier chain.</p>
        </>
    );
};