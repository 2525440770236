import React from 'react';
import SEO from './SEO';

export const Tenders = () => {
    return (
        <>
            {SEO({ title: "Tenders | EUROCOM | Goldmax", description: "High-end custom laptops. Flexible and unique solution." })}
            <h1>Tenders and Mass Orders</h1>
            <p><b>EUROCOM fight with the best SPECS and UNIQUE mobile platform, we dont fight Price!</b></p>
            <p>We have years of experience with tenders and ICT supplier selection procedure through European Union. We supplies academic clients, Research and Development industry and help participans of tenders and procurement offer very specific and super unique solution that simply competition cannot offer.</p>
            <p>If you deliver to government, private sector or any other institution and you are also interested in EUROCOM Technology, contact us today! We can also provide a <a target="_blank" href="https://www.eurocom-europe.com/testlend">Free Test Demo</a> so we persuade your client to buy EUROCOM Technology even before they need it.</p>

            <p>Contact us today at <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a> or call us <a href="tel:+420724583823">+420 724 583 823</a> for more details and ask how we can help you succeed in your ICT supplier chain.</p>
        </>
    );
};