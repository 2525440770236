import React, { useState } from 'react';
import { DynamicForm } from './DynamicForm';
import SEO from './SEO';
import './Contact.css'

const formSubmitBtnName = 'SEND';
const dialogHeader = 'Contact form';
const successMessage = 'Your request has been sent, we will contact you within the next business day.';
const errorMessage = 'Your request failed to sent, please contact us by e-mail or phone.';
const fields = [
    { prop: 'name', label: 'Full name', placeholder: '', type: 'text', size: 6, validation: 'Please enter your name.', validationtype: 'required' },
    { prop: 'firm', label: 'Company name', placeholder: '', type: 'text', size: 6, validation: '', validationtype: '' },
    { prop: 'email', label: 'E-mail', placeholder: '', type: 'email', size: 6, validation: 'Please enter your email address.', validationtype: 'email' },
    { prop: 'phone', label: 'Phone number', placeholder: '', type: 'text', size: 6, validation: 'Please enter your phone number.', validationtype: 'phone' },
    { prop: 'message', label: 'Youe message', placeholder: '', type: 'textarea', size: 12, validation: '', validationtype: '' }
];
let dynamicObject = {};
fields.map(item => dynamicObject[item.prop] = '');

export const Contact = () => {    
    const [object, setObject] = useState(dynamicObject);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogBody, setDialogBody] = useState('');

    const handleObjectChange = e => {
        const { id, value } = e.target;
        setObject(prevObj => ({ ...prevObj, [id]: value }));
    };
    const onSubmit = () => {
        setLoading(true);
        sendFomrAsync();
    };
    const onCloseDialog = () => {
        setDialogOpen(false);
    }

    const sendFomrAsync = async () => {       
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };
        const response = await fetch('form?id=5', requestOptions);
        if (response.ok) {
            setDialogBody(successMessage);
            setObject(dynamicObject);
        }
        else {
            setDialogBody(errorMessage);
        }
        setLoading(false);
        setDialogOpen(true);
    }

    return (
        <>
            {SEO({ title: "Contact | EUROCOM | Goldmax", description: "High-end custom laptops. Flexible and unique solution." })}
            <h1>Contact us</h1> 
            <div className="contact-group">
                <div className="contact-section">
                    <p><strong>EUROCOM CANADA office:</strong></p>
                    <p>Eurocom Corporation</p>
                    <p>148 Colonnade Road</p>
                    <p>Nepean, Ontario K2E 7R4 Canada</p>
                </div>

                <div className="contact-section">
                    <p><strong>EUROCOM EUROPE office:</strong></p>
                    <p>GOLDMAX SERVICES s.r.o.</p>
                    <p>Dolany 616, Dolany 783 16</p>
                    <p>Czech Republic</p>
                </div>

                <div className="contact-section"> 
                    <p>ID: 28572807</p>  
                    <p>VAT: CZ28572807</p>
                </div>
            </div>

            <div className="contact-group">                
                    <p><strong>Price Quote, Tender Requests, Questions?</strong></p>
                    <p>Company CEO – Ing. Ondřej Káhn</p>                    
                    <p><a href="tel:00420724583823">00420 724583823</a> | <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a></p>                                     
            </div>                            
            <DynamicForm fields={fields} object={object} submitBtnName={formSubmitBtnName} onChangeObject={handleObjectChange} onSubmitForm={onSubmit} loading={loading} dialogHeader={dialogHeader} dialogBody={dialogBody} dialogOpen={dialogOpen} onCloseDialog={onCloseDialog} />

            <p style={{ paddingTop: '24px' }}>Contact us today at <a href="mailto:sales@eurocom-europe.com">sales@eurocom-europe.com</a> or call us <a href="tel:+420724583823">+420 724 583 823</a> for more details and ask how we can help you succeed in your ICT supplier chain.</p>

        </>
    );
};