import React from 'react';
import SEO from './SEO';

export const VR = () => {
    return (
        <>
            {SEO({ title: "VR technology laptop| EUROCOM | Goldmax", description: "Laptop for VR oculus and development." })}
            <h1>VR/AR Technology</h1>
            <h2>Laptop or PC for virtual reality?</h2>

            <p style={{ paddingTop: "4px" }}>First, let's summarise what virtual headsets can be purchased today. There are those typically Oculus Rift/Go, HTC Vive, Pimax, etc. Virtual reality seems like a bogeyman in conjunction with a laptop (especially in the graphics card area), but the concerns are not misplaced. The specs as a minimum requirement for HW already start at the old GTX 1060 6GB, which is a 5 year old graphics card. Of course, times are different today and several years have passed since the first VR experiments, so the question arises, what HW would we recommend for Virtual Reality today, either in terms of use or even VR application development?</p>
            <p><b>Recommended: RTX 3070/RTX 3080 combined with 64GB RAM 3200MHz + fast NVMe Disk. Then choose a more powerful processor, so a full-featured Core i7/Core i9 from 2020 and newer.</b> However, cooling is an important aspect, because if you are going to put a load on the laptop, it should be ready for it, and this is far from being the case with competing and cheaper laptops. A classic gaming laptop for 1200-1600 EUR will barely last you under warranty, unfortunately these models are not designed for long term load.</p>
            <p style={{ paddingBottom: "48px" }}>EUROCOM, however, counts on the fact that clients use laptops 24/7, so we are not afraid to sell even machines where the laptop runs for rendering for many hours or several days at a time, and we also register such clients and they are still working happily today. And that we are more expensive? Imagine you have a 6 months old laptop that breaks down, you have to send it somewhere to be serviced and you are not too happy with it either. At that time you don't care if it cost 10k less or more, the investment as such doesn't please you and loses its meaning, so the money saved has almost no value. The important thing is to function and rely too much on our piece of HW every day, don't you think?</p>

            <img className="full-img" src="images/static-content/vr_1.jpg" />
            <img className="full-img" src="images/static-content/vr_2.jpg" />
            <h5 style={{ marginTop: '24px' }} ><b>AR/VR focused industries:</b></h5>
            <img className="full-img" src="images/static-content/vr_3.jpg" />
            <img className="full-img" src="images/static-content/vr_4.jpg" />
            <img style={{ marginTop: '40px' }} className="full-img" src="images/static-content/vr_5.jpg" />
            <img className="full-img" src="images/static-content/vr_6.jpg" />
            <img style={{ marginTop: '40px' }} className="full-img" src="images/static-content/vr_7.jpg" />            
        </>
    );
};