import React, { Component } from 'react';
import './ANotebookComponent.css';
import Loader from './Loader';
import { authHeader } from '../helpers/auth-header';

//// Require FroalaEditor JS files.
//import 'froala-editor/js/froala_editor.pkgd.min.js';
//import 'froala-editor/js/plugins.pkgd.min.js';
//// Require FroalaEditor CSS files.
//import 'froala-editor/css/froala_style.min.css';
//import 'froala-editor/css/froala_editor.pkgd.min.css';
//import FroalaEditor from 'react-froala-wysiwyg';

// Import Sun Editor's files
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
// Sun Editor's configuration
const editorDefaultStyle = 'font-family: "Quicksand", sans-serif; font-size: 14px; height: auto; padding: 24px 0px; margin: 0;';
const editorButtonList = [
    ['undo', 'redo'],
    ['formatBlock', 'fontSize', 'bold', 'underline', 'italic', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'removeFormat'],
    ['outdent', 'indent', 'align', 'list', 'table', 'horizontalRule'],
    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print']
]
const editorContainerHideBorder = { position: 'relative', width: '100%', margin: '0 auto', outline: '1px solid white', outlineOffset: '-1px' };
//const editorBotNavHideBar = { position: 'absolute', bottom: '0', width: '100%', height: "18px", backgroundColor: "#fff" };

export class NotebookImage {    
    base64Data = "";
    link = "";
    isDefault = false;
}
export class NDetail {
    description = "";
    descriptionEnglish = "";
    technicalSpecification = "";
    support = "";
}
export class A_Notebook_VM {
    components = [];
    notebookName = "";
    notebookDescription = "";
    notebookPrice = 0;
    notebookDiscountPrice = 0;
    notebookDiscountNote = "";
    type = 0;
    isUnconfigurable = false;
    notebookImages = [];
    notebookDetail = new NDetail();
}

//var froalaDescriptionContent = "";
//var froalaTechSpecContent = "";

const readUploadedFileAsData = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException("Problem parsing input file."));
        };

        temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
        };
        temporaryFileReader.readAsDataURL(inputFile);
    });
};
const readUploadedFiles = async (e) => {    
    try
    {
        const promises = [];
        for (var i = 0; i < e.target.files.length; i++) {
            promises.push(readUploadedFileAsData(e.target.files[i]));
        }
        let base64Array = await Promise.all(promises);
        return base64Array;
    }
    catch (e) {
        console.log("Error" + e)
    }
}

export class ANotebookComponent extends Component {
    static displayName = ANotebookComponent.name;
    
    constructor(props) {
        super(props);
        this.state = { notebook: new A_Notebook_VM(), className: '', loading: false, results: []};
        this.notebookName = this.notebookName.bind(this);
        this.notebookDescription = this.notebookDescription.bind(this);
        this.notebookPrice = this.notebookPrice.bind(this);
        this.notebookDiscountPrice = this.notebookDiscountPrice.bind(this);
        this.notebookDiscountNote = this.notebookDiscountNote.bind(this);
        this.notebookType = this.notebookType.bind(this);
        this.handleChangePrice = this.handleChangePrice.bind(this);
        this.notebookUnconfigurable = this.notebookUnconfigurable.bind(this);
        this.handleChangePrice = this.handleChangePrice.bind(this);
        this.handleChangeDiscountPrice = this.handleChangeDiscountPrice.bind(this);
        this.handleChangeDiscountNote = this.handleChangeDiscountNote.bind(this);
        this.toggleClass = this.toggleClass.bind(this);
        this.childToggleClass = this.childToggleClass.bind(this);
        this.defaultToggleClass = this.defaultToggleClass.bind(this);       
        this.inputClick = this.inputClick.bind(this);
        this.setSlot1 = this.setSlot1.bind(this);
        this.setSlot2 = this.setSlot2.bind(this);
        this.setSlot3 = this.setSlot3.bind(this); 
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
        this.defaultToggleImage = this.defaultToggleImage.bind(this);
        this.deleteImage = this.deleteImage.bind(this); 
        //this.handleFroalaEditorDescription = this.handleFroalaEditorDescription.bind(this);
        //this.handleFroalaEditorTechSpec = this.handleFroalaEditorTechSpec.bind(this);
        this.handleSunEditorDescription = this.handleSunEditorDescription.bind(this);
        this.handleSunEditorDescriptionEnglish = this.handleSunEditorDescriptionEnglish.bind(this);
        this.handleSunEditorTechSpec = this.handleSunEditorTechSpec.bind(this);
        this.handleSunEditorSupport = this.handleSunEditorSupport.bind(this);
    }
    //handleFroalaEditorDescription(value) {
    //    const { notebook } = this.state;
    //    notebook.notebookDetail.description = value;
    //    this.setState({ notebook });
    //}
    //handleFroalaEditorTechSpec(value) {
    //    const { notebook } = this.state;
    //    notebook.notebookDetail.technicalSpecification = value;
    //    this.setState({ notebook });
    //}

    handleSunEditorDescription(value) {
        const { notebook } = this.state;
        notebook.notebookDetail.description = value;
        this.setState({ notebook });
    }
    handleSunEditorDescriptionEnglish(value) {
        const { notebook } = this.state;
        notebook.notebookDetail.descriptionEnglish = value;
        this.setState({ notebook });
    }
    handleSunEditorTechSpec(value) {
        const { notebook } = this.state;
        notebook.notebookDetail.technicalSpecification = value;
        this.setState({ notebook });
    }
    handleSunEditorSupport(value) {
        const { notebook } = this.state;
        notebook.notebookDetail.support = value;
        this.setState({ notebook });
    }

    notebookName(e) {
        const value = e.target.value;
        const { notebook } = this.state;
        notebook.notebookName = value;
        this.setState({ notebook });
    }
    notebookDescription(e) {
        const value = e.target.value;
        const { notebook } = this.state;
        notebook.notebookDescription = value;
        this.setState({ notebook });
    }
    notebookPrice(e) {
        var value = e.target.value;
        if (value == "") {  
            value = 0;
        }  
        const { notebook } = this.state;
        notebook.notebookPrice = parseFloat(value);
        this.setState({ notebook });
    }
    notebookDiscountPrice(e) {
        var value = e.target.value;
        if (value == "") {
            value = 0;
        }  
        const { notebook } = this.state;
        notebook.notebookDiscountPrice = parseFloat(value);
        this.setState({ notebook });
    }
    notebookDiscountNote(e) {
        const value = e.target.value;
        const { notebook } = this.state;
        notebook.notebookDiscountNote = value;
        this.setState({ notebook });
    }
    notebookType(e) {
        const value = e.target.value;
        const { notebook } = this.state;
        notebook.type = parseInt(value);
        this.setState({ notebook });
    }
    notebookUnconfigurable(e) {
        const { notebook } = this.state;
        notebook.isUnconfigurable = !this.state.notebook.isUnconfigurable;
        if (!notebook.isUnconfigurable) {
            notebook.notebookDiscountPrice = 0;
            notebook.notebookDiscountNote = "";
        }
        this.setState({ notebook });
    }
    toggleClass(e) {
        const { id } = e.target;
        if (id != '') {
            const { notebook } = this.state;
            if (notebook.isUnconfigurable) {
                if (notebook.components[id].component.className !== "Gpu" && notebook.components[id].component.className !== "M2" && notebook.components[id].component.className !== "HardDisk" && notebook.components[id].component.className !== "Others") {
                    console.log(notebook.components[id].component.className);
                    var index = notebook.components.findIndex(x => x.component.className === notebook.components[id].component.className && x.isSelected);
                    console.log(index);
                    if (index !== -1 && index != id) notebook.components[index].isSelected = false;
                }
                notebook.components[id].isSelected = !this.state.notebook.components[id].isSelected;
            }
            else {
                notebook.components[id].isSelected = !this.state.notebook.components[id].isSelected;
            }            
            this.setState({ notebook });
        }
    };
    childToggleClass(e) {
        e.stopPropagation();
        const { id } = e.target;
        if (id != '') {
            const { notebook } = this.state;
            if (notebook.isUnconfigurable) {
                if (notebook.components[id].component.className !== "Gpu" && notebook.components[id].component.className !== "M2" && notebook.components[id].component.className !== "HardDisk" && notebook.components[id].component.className !== "Others") {
                    console.log(notebook.components[id].component.className);
                    var index = notebook.components.findIndex(x => x.component.className === notebook.components[id].component.className && x.isSelected);
                    console.log(index);
                    if (index !== -1 && index != id) notebook.components[index].isSelected = false;
                }
                notebook.components[id].isSelected = !this.state.notebook.components[id].isSelected;
            }
            else {
                notebook.components[id].isSelected = !this.state.notebook.components[id].isSelected;
            }            
            this.setState({ notebook });
        }
    };
    defaultToggleClass(e) {
        e.stopPropagation();
        const { id } = e.target;
        if (id != '') {
            const { notebook } = this.state;
            notebook.components[id].isDefault = !this.state.notebook.components[id].isDefault;
            this.setState({ notebook });
        }
    };
    inputClick(e) {
        e.stopPropagation();
    }
    handleChangePrice(e) {
        var value = e.target.value;
        if (value == "") {
            value = 0;
        }  
        const { id } = e.target;
        const { notebook } = this.state;
        notebook.components[id].price = parseFloat(value);
        this.setState({ notebook });        
    }
    handleChangeDiscountPrice(e) {
        var value = e.target.value;
        if (value == "") {
            value = 0;
        }  
        const { id } = e.target;
        const { notebook } = this.state;
        notebook.components[id].discountPrice = parseFloat(value);
        this.setState({ notebook });
    }
    handleChangeDiscountNote(e) {
        const value = e.target.value;
        const { id } = e.target;
        const { notebook } = this.state;
        notebook.components[id].discountNote = value;
        this.setState({ notebook });
    }
    setTitle(className) {
        if (this.state.className != className) {
            this.state.className = className;
            return <h4>{className}</h4>
        }
    }
    setSlotContainer(className, id, name) {
        if (className == "Gpu") {
            return (
                <div className="group-container">
                    {name != "NONE" && <div id={id} key={id} onClick={this.setSlot2} className={this.state.notebook.components[id].slot2 ? "component-slot component-slot-selected" : "component-slot"}>SLOT 2</div>}
                    <div id={id} key={id} onClick={this.setSlot1} className={this.state.notebook.components[id].slot1 ? "component-slot component-slot-selected" : "component-slot"}>SLOT 1</div>
                </div>
            )
        }
        if (className == "HardDisk" || className == "M2") {
            return (
                <div className="group-container">
                    {name != "NONE" && <div id={id} key={id} onClick={this.setSlot3} className={this.state.notebook.components[id].slot3 ? "component-slot component-slot-selected" : "component-slot"}>SLOT 3</div>}
                    {name != "NONE" && <div id={id} key={id} onClick={this.setSlot2} className={this.state.notebook.components[id].slot2 ? "component-slot component-slot-selected" : "component-slot"}>SLOT 2</div>}
                    <div id={id} key={id} onClick={this.setSlot1} className={this.state.notebook.components[id].slot1 ? "component-slot component-slot-selected" : "component-slot"}>SLOT 1</div>
                </div>
            )
        }
    }
    setSlot1(e) {
        e.stopPropagation();
        const { id } = e.target;
        const { notebook } = this.state;
        notebook.components[id].slot1 = !this.state.notebook.components[id].slot1;
        this.setState({ notebook });
    }
    setSlot2(e) {
        e.stopPropagation();
        const { id } = e.target;
        const { notebook } = this.state;
        notebook.components[id].slot2 = !this.state.notebook.components[id].slot2;
        this.setState({ notebook });
    }
    setSlot3(e) {
        e.stopPropagation();
        const { id } = e.target;
        const { notebook } = this.state;
        notebook.components[id].slot3 = !this.state.notebook.components[id].slot3;
        this.setState({ notebook });
    }

    componentDidMount() {
        const { notebook } = this.props; 
        //froalaDescriptionContent = notebook.notebookDetail.description;
        //froalaTechSpecContent = notebook.notebookDetail.technicalSpecification;
        this.setState({ notebook });
    }

    defaultToggleImage(e) {
        e.stopPropagation();
        const { id } = e.target;
        if (id != '') {
            const { notebook } = this.state;
            notebook.notebookImages[id].isDefault = !this.state.notebook.notebookImages[id].isDefault;
            this.setState({ notebook });
        }
    };
    
    deleteImage(e) {
        const { id } = e.target;
        const { notebook } = this.state;       
        if (!window.confirm("Opravdu chceš smazat obrázek?")) {
            return;
        }
        else {
            notebook.notebookImages.splice(id, 1);
            this.setState({ notebook });
        }
    }

    async uploadMultipleFiles(e) {  
        this.setState({ loading: true });
        const { files } = e.target;
        const { notebook } = this.props;
        const base64Array = await readUploadedFiles(e);
        for (var i = 0; i < base64Array.length; i++) {
            let notebookImage = new NotebookImage();
            notebookImage.base64Data = base64Array[i];
            notebookImage.link = URL.createObjectURL(files[i])
            notebook.notebookImages.push(notebookImage)            
        }
        this.setState({ notebook: notebook, loading: false });        
    }

    static renderNotebookTable(_this) {

        return (
            _this.state.notebook.components.map((p, i) =>
                <React.Fragment>
                    {_this.setTitle(p.component.className)}                    
                    <div id={i} key={i} className={p.isSelected ? "component-container component-container-selected rounded" : "component-container rounded"} onClick={_this.toggleClass}>
                        <div id={i} key={i} onClick={_this.childToggleClass} className="col-sm-7 component-name">{p.component.name}; {p.component.description}</div>
                        {p.component.name != "NONE" && <input className="col-sm-1" id={i} key={i} type="number" placeholder="Cena" value={p.price == 0 ? "" : p.price} onChange={_this.handleChangePrice} onClick={_this.inputClick} />}
                        {p.component.name != "NONE" && <input className="col-sm-1" id={i} key={i} type="number" placeholder="Sleva" value={p.discountPrice == 0 ? "" : p.discountPrice} onChange={_this.handleChangeDiscountPrice} onClick={_this.inputClick} />}
                        {p.component.name != "NONE" && <input className="col-sm-2" id={i} key={i} type="text" placeholder="Poznámka ke slevě" value={p.discountNote == "" ? "" : p.discountNote} onChange={_this.handleChangeDiscountNote} onClick={_this.inputClick} />}
                        {_this.state.notebook.isUnconfigurable ? null : <div id={i} key={i} onClick={_this.defaultToggleClass} className={p.isDefault ? "col-sm-1 component-default component-default-selected" : "col-sm-1 component-default"}>STANDARD</div>}
                        {_this.setSlotContainer(p.component.className, i, p.component.name)}
                    </div>
                </React.Fragment>
            )
        );
    }
        
    render() {   
        let loader = this.state.loading && <Loader loading={this.state.loading} />

        return ( 
            <div> 
                {loader}
                <input type="text" placeholder="Název notebooku" value={this.state.notebook.notebookName == "" ? "" : this.state.notebook.notebookName} onChange={this.notebookName} className="form-control input-text col-sm-12" />
                <textarea rows="3" placeholder="Popis notebooku oddělyný středníky (první;druhý;třetí;...)" value={this.state.notebook.notebookDescription == "" ? "" : this.state.notebook.notebookDescription} onChange={this.notebookDescription} className="form-control input-textarea col-sm-12" />                
                <div className="group-container"> 
                    <div className="third-container input-r-padding">
                        <input type="number" placeholder="Celková cena notebooku" value={this.state.notebook.notebookPrice == 0 ? "" : this.state.notebook.notebookPrice} onChange={this.notebookPrice} className="form-control input-text" />
                    </div>
                    <div className="third-container input-c-padding">
                        <input type="number" placeholder="Celková cena po slevě" value={this.state.notebook.notebookDiscountPrice == 0 ? "" : this.state.notebook.notebookDiscountPrice} onChange={this.notebookDiscountPrice} className="form-control input-text" disabled={this.state.notebook.isUnconfigurable ? "" : "disabled"} />
                    </div>
                    <div className="third-container input-l-padding">
                        <input type="text" placeholder="Poznámka ke slevě" value={this.state.notebook.notebookDiscountNote == "" ? "" : this.state.notebook.notebookDiscountNote} onChange={this.notebookDiscountNote} className="form-control input-text" disabled={this.state.notebook.isUnconfigurable ? "" : "disabled"} />
                    </div>
                    <div className="third-container input-r-padding hidden-file-input">
                        <label for="file-upload" className="input-file-upload">Vybrat obrázky</label>
                        <input id="file-upload" type="file" multiple accept="image/*" onChange={this.uploadMultipleFiles} />  
                    </div>
                    <div className="third-container input-c-padding">
                        <select value={this.state.notebook.type} onChange={this.notebookType} className="form-control input-select">
                            <option value="0">Top</option>
                            <option value="1">Workstation</option>
                            <option value="2">Server</option>
                            <option value="3">Enterprise</option>
                            <option value="4">Gaming</option>
                        </select>
                    </div>
                    <div className="third-container input-l-padding">
                        <div onClick={this.notebookUnconfigurable} className={this.state.notebook.isUnconfigurable ? "form-control input-button component-container-selected" : "form-control input-button"} >NECONFIGUREELNÝ</div>
                    </div>      
                </div>
                <div className="row-container top-offset">
                    <div className="img-gallery top-offset">
                        {this.state.notebook.notebookImages.map((img, i) => (
                            <div className="file-image-container">
                                <img id={i} key={i} onClick={this.deleteImage} className="file-image" src={img.link} alt="..." />
                                <div id={i} key={i} onClick={this.defaultToggleImage} className={img.isDefault ? "file-image-text file-image-text-selected" : "file-image-text"}>NÁHLEDOVÝ</div>
                            </div>
                        ))}
                    </div>
                </div>   
                {ANotebookComponent.renderNotebookTable(this)}
                <h2 style={{ paddingTop: '24px' }}>Detail</h2>
                <h5 style={{ paddingTop: '2px', paddingBottom: '12px' }}>Popis</h5>
                <div style={editorContainerHideBorder}>
                    <SunEditor
                        setContents={this.props.notebook.notebookDetail.description}
                        onChange={this.handleSunEditorDescription}
                            setOptions={{ defaultStyle: editorDefaultStyle, buttonList: editorButtonList }} />
                </div>

                <h5 style={{ paddingTop: '22px', paddingBottom: '12px' }}>Popis <b>.com web</b></h5>
                <div style={editorContainerHideBorder}>
                    <SunEditor
                        setContents={this.props.notebook.notebookDetail.descriptionEnglish}
                        onChange={this.handleSunEditorDescriptionEnglish}
                        setOptions={{ defaultStyle: editorDefaultStyle, buttonList: editorButtonList }} />
                </div>
                {/*<FroalaEditor                    
                    tag='textarea'
                    config={{                        
                        placeholderText: "Popis pro detail notebooku",
                        imageUploadURL: 'admin/EditorUploadImage',
                        imageUploadMethod: 'POST',                        
                        events: {
                            'image.removed': function (img) {
                                const requestOptions = {
                                    method: 'POST',                                    
                                    headers: authHeader(),
                                    body: JSON.stringify(img.attr('src'))
                                };
                                fetch('admin/EditorDeleteImage/', requestOptions);
                            },
                            'initialized': async function () {
                                await new Promise(resolve => setTimeout(resolve, 1));
                                this.html.set(froalaDescriptionContent);                                
                            }                            
                        }                        
                    }}
                    model={this.state.notebook.notebookDetail.description}
                    onModelChange={this.handleFroalaEditorDescription} /> 
                */}
                {this.state.notebook.isUnconfigurable
                    ? null
                    : <>
                        <h5 style={{ paddingTop: '22px', paddingBottom: '12px' }}>Technická specifikace</h5>
                        <div style={editorContainerHideBorder}>
                            <SunEditor
                                setContents={this.props.notebook.notebookDetail.technicalSpecification}
                                onChange={this.handleSunEditorTechSpec}
                                setOptions={{ defaultStyle: editorDefaultStyle, buttonList: editorButtonList }} />
                        </div>
                    </>
                }
                {/*<FroalaEditor
                    tag='textarea'
                    config={{
                        placeholderText: "Tecnická specifikace pro detail notebooku",                        
                        events: {                            
                            'initialized': async function () {
                                await new Promise(resolve => setTimeout(resolve, 1));
                                this.html.set(froalaTechSpecContent);
                            }
                        }
                    }}
                    model={this.state.notebook.notebookDetail.technicalSpecification}
                    onModelChange={this.handleFroalaEditorTechSpec} />*/}

                <h5 style={{ paddingTop: '22px', paddingBottom: '12px' }}>Podpora</h5>
                <div style={editorContainerHideBorder}>
                    <SunEditor
                        setContents={this.props.notebook.notebookDetail.support}
                        onChange={this.handleSunEditorSupport}
                        setOptions={{ defaultStyle: editorDefaultStyle, buttonList: editorButtonList }} />
                </div>
            </div>
        );
    }
}
export default ANotebookComponent;