import React, { Component } from 'react';
import './DialogInfo.css';

export class DialogInfo extends Component {
    static displayName = DialogInfo.name;

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <React.Fragment >
                <div className="modal">
                    <div className="modal-content">
                        <div className="modal-header">                            
                            <h5>{this.props.header}</h5>
                        </div>
                        <div className="modal-body">
                            <p>{this.props.children}</p>                            
                        </div>
                        <div className="modal-footer">
                            <button className="submit-button rounded" onClick={this.props.onClose}>CLOSE</button>                                                     
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
