export function classNameToString(className) {
    switch (className) {
        case 'System': return 'System';
        case 'Display': return 'LCD Display';
        case 'GSync': return 'G-Sync';
        case 'DisplayCalibaration': return 'Display Calibaration';
        case 'Cpu': return 'CPU';
        case 'CpuHeatsink': return 'CPU Heatsink';
        case 'Gpu': return 'GPU';
        case 'GpuSlot1': return '1st GPU';
        case 'GpuSlot2': return '2nd GPU';
        case 'GpuHeatsink': return 'GPU Heatsink';
        case 'GpuTechnology': return 'GPU Technology';
        case 'Ram': return 'Memory (RAM) Configuration';
        case 'M2': return 'm.2 SSD';
        case 'M2Slot1': return '1st m.2 SSD';
        case 'M2Slot2': return '2nd m.2 SSD';
        case 'M2Slot3': return '3rd m.2 SSD';
        case 'HardDisk': return 'Drive: HDD, SSD or SSHD';
        case 'HardDiskSlot1': return '1st Drive: HDD, SSD or SSHD';
        case 'HardDiskSlot2': return '2nd Drive: HDD, SSD or SSHD';
        case 'HardDiskSlot3': return '3rd Drive: HDD, SSD or SSHD';
        case 'RaidOptions': return 'RAID Options';
        case 'WirelessLan': return 'Wireless LAN';
        case 'WebCamera': return 'Built-in Web Camera';
        case 'SecurityPackage': return 'Security - ANTI SPYING Package';
        case 'MobileBroadbandReady': return '4G/LTE Module';
        case 'BiometricDevice': return 'Biometric Device';
        case 'SecurityChip': return 'Embedded Security Chip';
        case 'EmbeddedSecurityChip': return 'Embedded Security Chip';
        case 'HDMIPort': return 'HDMI Port(s)';
        case 'ThunderboltPort': return 'Thunderbolt Port';
        case 'SmartCardReader': return 'Internal SmartCard Reader';
        case 'CardReader': return 'Internal Card Reader';
        case 'WarrantyPlan': return 'Warranty Plan';
        case 'KeyBoard': return 'Keyboard (Language)';
        case 'Bios': return 'BIOS';
        case 'OS': return 'Operating System';
        case 'Software': return 'Office/Business Productivity Software';
        case 'ACAdapter': return 'AC Adapter';
        case 'PowerCord': return 'Power Cord';
        case 'Batterry': return 'Extra Batterry';
        case 'TVTuner': return 'TV Tuner (Internal and/or External)';
        case 'OpticalDrive': return 'Optical Drive';
        case 'CarAcAdapter': return 'Car AC Adapter';
        case 'CarryingCase': return 'Carrying Case';
        case 'DockingStation': return 'Docking Stations, Hubs and Port Replicators';
        case 'ExternalOpticalDrive': return 'External Optical Drive';
        case 'ThermalPadsM2': return 'Extra Thermal Pads for M.2 SSD';
        case 'GamingAccessories': return 'Gaming Accessories';
        case 'ExternalDisplay': return 'External LCD Display';
        case 'GraphicsTabletStylus': return 'Graphics Tablet / Stylus';
        case 'Others': return 'Other Accessories';
        default: return 'Unknown'
    }
}