import React from 'react';
import './CaseStudies.css';
import SEO from './SEO';

export const CaseStudies = () => {
    return (
        <>
            {SEO({ title: "Case studies | EUROCOM | Goldmax", description: "Research and Development laptops for ARM and labs rendering purposes." })}
            <h1>Our Clients/Case Studies</h1>
            <h2>Typical course of a consultation or we want to get to know you:</h2>

            <p style={{ paddingTop: "4px" }}>Eurocom: <i className="dark-text">„Hello, how can I help you?“</i></p>
            <p>Client A: <i className="blue-text">„I would like a powerful 17" laptop for PS and 4K video in Premiere, I work in advertising.“</i></p>
            <p>Client B: <i className="green-text">„I am a programmer and I am looking for something powerful up to 4.000 EUR.“</i></p>

            <p>Eurocom: <i className="dark-text">„I understand the X/Y model is suitable for your business, where the highest priority for you is component speed <span className="blue-text">(Client A)</span> CUDA-enabled graphics plus fast RAM plus multi-core CPU <span className="green-text">(Client B)</span> CPU, RAM and then SSD.“</i></p>
            <p>Client A: <i className="blue-text">„OK, can you help me build the model?How much RAM and what storage would you recommend?“</i></p>
            <p>Client B: <i className="green-text">„Okay, do you have any fast LCDs that save your eyes?How's your service?“</i></p>

            <p>Eurocom: <i className="dark-text">„<span className="blue-text">(Client A)</span> Yes, we can help, we will tailor the design and send it to you for approval and further consultation.What HW do you have now and how much RAM do you estimate a larger project will take? <span className="green-text">(Client B)</span> We service by sending you a component for replacement without loss of warranty to the client, if we know where the problem is, so the laptop remains in working condition at all times (for example, replacement of RAM module , disk, keyboard, wifi module , graphics or adapter) and we recommend to choose 300Hz LCD model EUROCOM Sky Z7 R2.“</i></p>
            <p>Client A: <i className="blue-text">„Okay, my email and phone is XXX, do you also offer installment sales?“</i></p>
            <p>Client B: <i className="green-text">„OK, send me two price alternatives on how to set up the configuration and I'll make up my mind.“</i></p>

            <p>Eurocom: <i className="dark-text">„<span className="blue-text">(Client A)</span> We offer installment sales with a down payment of 1/3 of the price for 6-24 months and we do not require any debt-free or verify the creditworthiness of the client. <span className="green-text">(Client B)</span> I will build you a notebook according to our 13 years of experience, we will comment on each other and suggest changes or alternatives.“</i></p>
            <p>Client A: <i className="blue-text">„Thank you for the great support and service.“</i></p>
            <p>Client B: <i className="green-text">„Thanks for the awesome response.“</i></p>

            <p>This is a very abbreviated but realistic scenario of how we try to get to know the client. Next, we normally ask what HW the customer has been working on so far, what he thinks is holding him back, what other applications he uses and how he will use the laptop in the future. All of this helps us form an idea of the ideal laptop for a particular user, whose requirements are usually highly individual. With us, the client does not change the laptop in 2 years, but counts on an investment for at least 3-5 years, usually even longer. The lifetime of the laptop is our priority.</p>
            <p>EUROCOM has been helping to achieve higher professional results and breaking down the boundaries between laptops and desktops for many years. If you work on a computer for a living, then faster day-to-day operations are worthwhile and we can talk about the real return on investment (ROI) that a EUROCOM laptop represents.</p>

            <h2 style={{ paddingTop: "16px" }}>So who is our typical customer?</h2>

            <ul className="list-container">
                <li>Public sector and private enterprises using high-end computing and servers</li>
                <li>Engineering production, automotive subcontractors and production simulation</li>
                <li>Content creation for VR, AR and other advanced technologies, challenges and development</li>
                <li>Graphic/film studios specializing in high-resolution editing and photo editing</li>
                <li>CAx/GIS/PLM design in 3D, technology development in mechanics and particle flow</li>
                <li>IT specialists and programmers providing service and development of business/cloud applications</li>
                <li>Engineering and design offices providing construction implementation and coordination</li>
                <li>Database administrators, suppliers of ERP and complex enterprise information systems</li>
            </ul>
            <a href="https://www.facebook.com/EurocomTechnology" target="blank"><img className="full-img" src="images/static-content/casestudies_1.jpg" /></a>
            <a href="https://www.eurocom.com/ec/data/whitepapers/19.pdf" target="blank"><img className="full-img" src="images/static-content/casestudies_2.jpg" /></a>
            <a href="https://www.eurocom.com/ec/data/whitepapers/20.pdf" target="blank"><img className="full-img" src="images/static-content/casestudies_3.jpg" /></a>
        </>
    );
};