import React, { Component } from 'react';

export class Prepareing extends Component {
    static displayName = Prepareing.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="notFoundContainer">
                <h1 className="notFoundTitle">PŘIPRAVUJEME</h1>
                <h3 className="notFoundSubTitle">STRÁNKA BUDE JIŽ BRZY K DISPOZICI</h3>
                <p className="notFoundDesc">
                    Děkujeme za trpělivost a pochopení.
                </p>
            </div>
        );
    }
}