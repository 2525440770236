import React, { Component } from 'react';
import Loader from './Loader';
import { Route } from 'react-router-dom';
import { GetCurrency } from './Configurator'
import { AppStateContext } from './AppStateContext'
import { ErrorNotFound } from './ErrorNotFound';
import { classNameToString } from '../helpers/classNameToString';
import './NotebookDetail.css';
import { NDetail } from './ANotebookComponent';
import SEO from './SEO';
// Import Sun Editor's files
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; 
import NotebookImage from './ANotebookComponent';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Sun Editor's configuration
const editorDefaultStyle = 'font-family: "Quicksand", sans-serif; font-size: 14px; height: auto; padding: 0; margin: 0;';
const editorBotNavHideBar = { position: 'absolute', bottom: '0', width: '100%', height: "18px", backgroundColor: "#fff" };
const editorContainerHideBorder = { position: 'relative', width: '100%', margin: '0 auto', outline: '2px solid white', outlineOffset: '-2px' };
//import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

export class Notebook {
    name = "";
    description = "";
    price = 0;
    discountPrice = 0;
    discountNote = "";    
    type = 0;
    isUnconfigurable = false;
    notebookImages = [];
    detail = new NDetail();
}

export class NotebookDetail extends Component {
    static displayName = NotebookDetail.name;
    static contextType = AppStateContext;

    constructor(props) {
        super(props);
        this.state = { notebook: new Notebook(), loading: true, selectedRowButton: 0, nav1: null, nav2: null, scmodel: null, fullScreen: false };
        this.handleRowButton = this.handleRowButton.bind(this);
        this.handleConfigButton = this.handleConfigButton.bind(this); 

        //const { id } = this.props.match.params;
        //if (id) {
        //    this.id = id;
        //} else {
        //    this.id = 0;
        //}

        const id = 0;
        const { urlName } = this.props.match.params || "";
        if (urlName) {
            this.urlName = urlName;
        } else {
            this.urlName = "";
        }

        //if (this.props.location.state) {
        //    this.id = this.props.location.state.id;
        //}
    }

    handleRowButton(e) {
        const { id } = e.target;
        this.setState({ selectedRowButton: id });
    }
    handleConfigButton(e) {
        e.stopPropagation();
        const { id } = e.target;
        //this.props.history.replace('/configurator', { id: id, type: this.type }); // HACK PROHLIZECE - SIPKA ZPET
        //this.props.history.push('/configurator', { id: id, type: this.type });
        this.props.history.push('/configurator/' + this.state.notebook.urlName, { type: this.type });
        window.scrollTo(0, 0);
    }

    async getConfiguratorAsync(id) {
        const requestOptions = { method: 'GET', 'Content-Type': 'application/json' };
        const response = await fetch('configurator/' + id, requestOptions);
        const data = await response.json();
        this.setState({ scmodel: data, loading: false });
    }
    handleShoppingCart = async (e) => {
        this.setState({ loading: true });
        const { id } = e.target;
        await this.getConfiguratorAsync(id);
        let keys = Object.keys(localStorage);
        /* STARY ZPUSOB RESIL JEN CELKOVY POCET POLOZEK V LOKALNIM ULOZISTI - (NELZE POUZIT) APPLE SAFARI SI UKLADA VLASTNI DATA DO LOKALNIHO ULOZISTE 
        keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();
        localStorage.setItem(keys.length, JSON.stringify(this.state.scmodel));
         */
        /*
        keys = Object.keys(localStorage);
        keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();    
        */

        keys = keys.filter(x => x.includes("gmscitem")).sort();
        var tempModel = this.state.scmodel;
        tempModel.notebook.detail = null;

        localStorage.setItem(`gmscitem-${keys.length}`, JSON.stringify(tempModel));

        this.context[1]((prevState) =>
            ({ ...prevState, scart: keys.length })
        );
        this.props.history.push('/shoppingcart');
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        //if (typeof this.id !== 'undefined') {
        //    if (this.id !== 0) {
        //        this.getNotebookAsync();
        //    }
        //} 
        if (typeof this.urlName !== 'undefined') {
            if (this.urlName !== "") {
                this.getNotebookAsync();
            }
        }
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    } 
    renderUnconfigSpec = () => {
        return <>
            {this.state.notebook.notebookComponent_RS.map(item => (                
                <div className="summary-row">
                    <div className="summary-item-classname">{classNameToString(item.componentClassName)}:</div>
                    <div className="summary-item-name">{item.component.name}; {item.component.description}</div>
                </div>
            ))}
        </>;        
    }

    render() {
        if (typeof this.id !== 'undefined') {
            if (this.id === 0) {
                return <Route path="*" component={ErrorNotFound} />
            }
        }
        let loader = this.state.loading && <Loader loading={this.state.loading} />

        const { notebook } = this.state;
        let notebookDescription = notebook.description + "";
        let description = [];
        let defaultImg = new NotebookImage();
        let otherImage = [];
        if (typeof this.state.notebook.notebookImages !== 'undefined' && this.state.notebook.notebookImages.length > 0) {
            defaultImg = this.state.notebook.notebookImages.find((rec) => { return rec.isDefault == true });
            if (typeof defaultImg === 'undefined') {
                defaultImg = new NotebookImage();
            }
            otherImage = this.state.notebook.notebookImages.filter((rec) => { return rec.isDefault != true });
        }
        if (typeof notebookDescription !== 'undefined' && notebookDescription != "") {
            description = notebookDescription.split(";");
        }

        return (
            <div style={{ paddingBottom: '64px', overflow: 'auto' }}>
                {SEO({ title: notebook.seoTitleEn, description: notebook.seoDescriptionEn, siteUrl: notebook.urlName, sourceImgUrl: defaultImg.link })}
                {loader}
                
                <h1>{this.state.notebook.nameEn}</h1>
                <div className="row-container top-offset">                   
                    <div className={this.state.fullScreen ? "slider-fullscreen-container" : "col-sm-6 nopadding"}
                        onClick={(e) => { e.stopPropagation(); this.setState({ fullScreen: false }) }}
                    >
                        
                        <div className={this.state.fullScreen ? "slider-fullscreen" : "detail-slider-container"}
                            onClick={(e) => e.stopPropagation() }
                        >
                            <Slider
                                style={{ margin: '0 24px' }}                                    
                                fade={true}
                                asNavFor={this.state.nav2}
                                ref={slider => (this.slider1 = slider)}
                                accessibility={false}
                                arrows={false}
                            >
                                <div>
                                    {defaultImg.link ? <img onClick={(e) => { e.stopPropagation(); this.setState({ fullScreen: !this.state.fullScreen }) }} className="slider-main-img" src={"https://www.goldmax.cz/" + defaultImg.link} /> : <span className="align-middle">PREViEW IMAGE NOT FOUND!</span>}
                                </div>
                                {otherImage.map(img =>
                                    <div>
                                        <img onClick={(e) => { e.stopPropagation(); this.setState({ fullScreen: !this.state.fullScreen }) }} className="slider-main-img" src={"https://www.goldmax.cz/" + img.link} />
                                    </div>
                                )}
                            </Slider>
                            <hr style={{ padding: '0px', margin: '12px 24px' }} />
                            <Slider style={{ padding: '0', margin: '0 24px' }}
                                asNavFor={this.state.nav1}
                                ref={slider => (this.slider2 = slider)}
                                slidesToShow={this.state.fullScreen ? 6 : 3}
                                swipeToSlide={true}
                                focusOnSelect={true}
                                centerMode={true}
                                autoplay={true}
                                autoplaySpeed={5000}
                                pauseOnHover={true}
                                cssEase={"linear"}
                                infinite={true}
                                accessibility={true}
                                arrows={true}>
                                <div>
                                    {defaultImg.link ? <img className="slider-nav-img" src={"https://www.goldmax.cz/" + defaultImg.link} /> : <span className="align-middle">PREViEW IMAGE NOT FOUND!</span>}
                                </div>
                                {otherImage.map(img =>
                                    <div>
                                        <img className="slider-nav-img" src={"https://www.goldmax.cz/" + img.link} />
                                    </div>
                                )}
                            </Slider>
                            {this.state.fullScreen ? null : <hr style={{ padding: '0px', margin: '12px 24px' }} />}
                           
                        </div>
                    </div>

                    <div className="col-sm-6 nopadding detail-description-container">
                        {description.map(desc =>
                            <div className="col-sm-12">• {desc}</div>
                        )} 
                        <br />
                        <div className="col-sm-12 detail-btn-container">                            
                            {this.state.notebook.isUnconfigurable
                                ? <div className="col-sm-12 padding-top-only" style={{ fontSize: '28px', letterSpacing: '-0.07em', padding: '0' }}><GetCurrency value={this.state.notebook.price} /></div>
                                : <div className="col-sm-12 padding-top-only" style={{ fontSize: '28px', letterSpacing: '-0.07em', padding: '0' }}><GetCurrency value={this.state.notebook.price} /></div>
                            }
                            <div className="col-sm-12" style={{ fontSize: '14px', margin: '0', padding: '0', marginTop: '-3px', opacity: '0.74' }} >21% VAT inc.</div>
                            {this.state.notebook.discountPrice != 0 && <div className="col-sm-12 nopadding">Celková cena po slevě: <GetCurrency value={this.state.notebook.discountPrice} /> 21% VAT inc.</div>}
                            {this.state.notebook.discountPrice != 0 && <div className="col-sm-12 nopadding">Poznámka ke slevě: {this.state.notebook.discountNote}</div>}
                            {this.state.notebook.isUnconfigurable
                                ? <button className="main-button rounded" style={{ marginTop: '20px' }} id={this.state.notebook.id} key={this.state.notebook.id} onClick={this.handleShoppingCart}>ADD TO CART</button>
                                : <button className="main-button rounded" style={{ marginTop: '20px' }} id={this.state.notebook.id} key={this.state.notebook.id} onClick={this.handleConfigButton}>CONFIGURE</button>
                            }                            
                        </div>
                    </div>    
                </div>

                <div className="detail-row-button-cotainer">
                    <span id={0} onClick={this.handleRowButton} className={this.state.selectedRowButton == 0 ? "detail-row-button detail-row-button-selected" : "detail-row-button"}>
                        DESCRIPTION
                    </span>
                    <span id={1} onClick={this.handleRowButton} className={this.state.selectedRowButton == 1 ? "detail-row-button detail-row-button-selected" : "detail-row-button"}>
                        SPECIFICATION
                    </span>                   
                    <span id={2} onClick={this.handleRowButton} className={this.state.selectedRowButton == 2 ? "detail-row-button detail-row-button-selected" : "detail-row-button"}>
                        SUPPORT
                    </span>
                </div >
                
                <div className="col-sm-12 nopadding detail-content">
                    {(this.state.selectedRowButton == 0 && this.state.notebook.detail.descriptionEnglish) && <div style={editorContainerHideBorder}><SunEditor setContents={this.state.notebook.detail.descriptionEnglish} enable={false} disable={true} showToolbar={false} setOptions={{ defaultStyle: editorDefaultStyle }} /><div style={editorBotNavHideBar}></div></div> /*<FroalaEditorView model={this.state.notebook.detail.description} />*/}
                    {this.state.selectedRowButton == 1 &&
                        (this.state.notebook.isUnconfigurable
                        ? this.renderUnconfigSpec()
                        : this.state.notebook.detail.technicalSpecification && <div style={editorContainerHideBorder}>
                            <SunEditor setContents={this.state.notebook.detail.technicalSpecification} enable={false} disable={true} showToolbar={false} setOptions={{ defaultStyle: editorDefaultStyle }} />
                            <div style={editorBotNavHideBar}>
                            </div>
                        </div>)}
                    {(this.state.selectedRowButton == 2 && this.state.notebook.detail.support) && <div style={editorContainerHideBorder}><SunEditor setContents={this.state.notebook.detail.support} enable={false} disable={true} showToolbar={false} setOptions={{ defaultStyle: editorDefaultStyle }} /> <div style={editorBotNavHideBar}></div></div> /*<FroalaEditorView model={this.state.notebook.detail.support} />*/}
                </div>
               
            </div>
        );
    }

    async getNotebookAsync() {
        const requestOptions = { method: 'GET', 'Content-Type': 'application/json' };
        //const response = await fetch('notebook/getone/' + this.id, requestOptions);
        //const data = await response.json();
        //this.setState({ notebook: data, loading: false });
        const response = await fetch('notebook/getonebyurl/' + this.urlName, requestOptions);
        if (response.status == 200) {
            const data = await response.json();
            this.id = data.id;
            this.setState({ notebook: data, loading: false });
        } else {
            this.id = 0;
            this.setState({ loading: false });
        } 
    }
}

