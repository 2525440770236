import React from 'react';
import SEO from './SEO';

export const Eurocom = () => {
    return (
        <>
            {SEO({ title: "EUROCOM EUROPE | Notebooky na míru | Goldmax", description: "High-end custom laptops. Flexible and unique solution." })}
            <h1>EUROCOM Czech Republic</h1>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>Czech Republic e-shop EUROCOM</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="https://www.goldmax.cz/" target="_blank" rel="noopener noreferrer">
                    www.goldmax.cz
                </a>
            </p>
        </>
    );
};