import React from "react"
import { Helmet } from "react-helmet"

const defaultUrl = "https://goldmax.cz";
const defaultImgUrl = "https://eurocom-europe.com/og-image.png";

const SEO = ({ title, description, siteUrl = "", sourceImgUrl = "", meta = [] }) => {
    return (
        <Helmet title={title}
            htmlAttributes={{ lang: "en" }}
            meta={[
                {
                    name: `description`,
                    content: description
                },
                {
                    property: `og:site_name`,
                    content: title
                },
                {
                    property: `og:title`,
                    content: title
                },
                {
                    property: `og:url`,
                    content: `${defaultUrl}/${siteUrl}`
                },
                {
                    property: `og:description`,
                    content: description
                },
                {
                    property: `og:image`,
                    content: `${defaultUrl}/${sourceImgUrl}`
                },
                {
                    property: `og:image`,
                    content: defaultImgUrl
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    property: `og:locale`,
                    content: `cs_CZ`
                }

            ]}
        />
    )
}
export default SEO;