import React, { useContext, useEffect, useState } from 'react';
/*import CartIcon from "./CartIcon";*/
import { Icon } from 'semantic-ui-react'
import { Menubar } from 'primereact/menubar';
import { useHistory } from "react-router-dom";
import { AppStateContext } from './AppStateContext'
//import { AppStateContext, AppStateDispatchContext } from './AppStateContext'


import 'primereact/resources/themes/saga-orange/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './NavMenu.css';


//export function onCountChange() {  
//    var cartCount = localStorage.length;
//    var user = localStorage.getItem("currentUser");
//    if (user) {
//        cartCount--;
//    }    
//}


//const start = <img alt="logo" src="logo.png" height="40" className="p-mr-2"></img>;

//const history = useHistory();
//var user = localStorage.getItem("currentUser");
//if (user) {
//    setCartCount(cartCount--);
//}   
//const set = (count = 0) => {
//    setCartCount(count)
//}

//const start = <img style={{height: '48px', paddingRight: '24px'}} src="images/logo.png" />

export const NavMenu = () => {
    //const [cartCount, setCartCount] = useState(localStorage.length);
    const history = useHistory();
    const items = [
        {
            label: 'Laptops',
            items: [ 
                {
                    label: 'In Stock/On SALE',
                    command: () => {                       
                        history.push('/notebooks-in-stock');
                    }
                },
                {
                    separator: true
                },
                {
                    label: 'Mobile Supercomputers',
                    command: () => {
                    /*history.push('/notebooks', { type: 0 });*/
                        history.push('/mobile-supercomputers');
                    }
                },
                {
                    label: 'Mobile Workstations',
                    command: () => {
                    /*history.push('/notebooks', { type: 1 });*/
                        history.push('/mobile-workstations');
                    }
                },
                {
                    label: 'Mobile Servers',
                    command: () => {
                    /*history.push('/notebooks', { type: 2 });*/
                        history.push('/mobile-servers');
                    }
                },
                {
                    label: 'Enterprise Laptops',
                    command: () => {
                    /*history.push('/notebooks', { type: 3 });*/
                        history.push('/enterprise-laptops');
                    }
                },
                {
                    label: 'Gaming Laptops',
                    command: () => {
                    /*history.push('/notebooks', { type: 4 });*/
                        history.push('/gaming-laptops');

                    }
                }
            ]
        },
        {
            label: 'EUROCOM Technology',
            items: [
                {
                    label: 'Why GO with EUROCOM?',
                    command: () => {
                        history.push('/whyeurocom');
                    }
                },
                {
                    label: 'Our Clients/Case studies',
                    command: () => {
                        history.push('/casestudies');
                    }
                },
                {
                    label: 'VR/AR Technology',
                    command: () => {
                        history.push('/vr');
                    }
                },
                {
                    label: 'Students discounts',
                    command: () => {
                        history.push('/students');
                    }
                },
                {
                    label: 'Multi GPU/Risers',
                    command: () => {
                        history.push('/multigpu');
                    }
                },
                {
                    label: 'Reviews and Awards',
                    command: () => {
                        history.push('/review');
                    }
                },                

            ]
        },
        {
            label: 'Support and Upgrade',
            items: [
                {
                    label: 'Support Request',
                    items: [
                        {
                            label: 'Cleaning and Maintenance',
                            command: () => {
                                history.push('/cleaning');
                            }
                        },
                        {
                            label: 'RMA Request form',
                            command: () => {
                                history.push('/rma');
                            }
                        },
                        {
                            label: 'Courier Pick-up Order',
                            command: () => {
                                history.push('/pickup');
                            }
                        }                        
                    ]

                },
                {
                    label: 'GPU Upgrade and Parts',
                    command: () => {
                        history.push('/upgrade');
                    }
                },
                {
                    label: 'Tutorial/Guides/Videos',
                    command: () => {
                        history.push('/lib');
                    }
                },
                {
                    label: 'Download Drivers',
                    command: () => {
                        history.push('/drivers');
                    }
                },
            ]
        },
        {
            label: 'Tenders and Specials',
            items: [
                {
                    label: 'Price Quote Request',
                    command: () => {
                        history.push('/quotationrequest');
                    }
                },
                {
                    label: 'Tenders and Mass Orders',
                    command: () => {
                        history.push('/tenders');
                    }
                },
                {
                    label: 'Test Demo Request',
                    command: () => {
                        history.push('/testlend');
                    }
                }
            ]
        },
        {
            label: 'Contacts',
            items: [
                {
                    label: 'Contact us',
                    command: () => {
                        history.push('/contact');
                    }
                },
                {
                    label: 'EUROCOM Czech Republic',
                    command: () => {
                        history.push('/eurocom');
                    }
                },
                {
                    label: 'Cooperation',
                    command: () => {
                        history.push('/cooperation');
                    }
                },
                {
                    label: 'Social Media',
                    items: [
                        {
                            label: 'Facebook',
                            command: () => {
                                window.open('https://www.facebook.com/Goldmax.cz?ref=hl', '_blank');                                
                            }
                        },
                        {
                            label: 'Instagram',
                            command: () => {
                                window.open('https://www.instagram.com/goldmaxcz/', '_blank');
                            }
                        },
                        {
                            label: 'Youtube',
                            command: () => {
                                window.open('https://www.youtube.com/user/EurocomTechnology', '_blank');
                            }
                        }
                    ]
                }
            ]
        }
    ];

    const [appState, setAppState] = useContext(AppStateContext);

    //const { scart, currency } = useContext(AppStateContext);
    //const setAppState = useContext(AppStateDispatchContext);
    const [ currencies, setCurrencies ] = useState({});
    const end = <div className="menu-right-container"> 
        <div className="dropdown">
            <button className="dropbtn">{appState.currency === undefined ? "" : appState.currency.code}</button>
            <div className="dropdown-content">                
                {/*{Array.from(currencies).map(item => item.code != currency.code && <a onClick={() => { setAppState({ currency: item, scart: localStorage.length }) }}>{item.code}</a> )}*/}
                {Array.from(currencies).map((item, index) => item.code != appState.currency.code && <a key={index} onClick={() => { setAppState((prevState) => ({ ...prevState, currency: item })) }}>{item.code}</a>)}

            </div>
        </div>
        <div className="shoppingcart" onClick={() => history.push('/shoppingcart')}><span className="cart-icon"><Icon name='shopping bag' size='large' /></span><span className="cart-icon-number">{appState.scart}</span></div>
        </div>;

    //this.props.setGlobalState(prevGlobalState => ({
    //    counter: prevGlobalState.counter + 1
    //}))

    const getCurrenciesAsync = async () => {
        const requestOptions = { method: 'GET' };
        const response = await fetch('admin/getallcurrency', requestOptions);
        const data = await response.json();
        setCurrencies(data);
        let keys = Object.keys(localStorage);
        /* STARY ZPUSOB RESIL JEN CELKOVY POCET POLOZEK V LOKALNIM ULOZISTI - (NELZE POUZIT) APPLE SAFARI SI UKLADA VLASTNI DATA DO LOKALNIHO ULOZISTE 
        keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();        
        */
        keys = keys.filter(x => x.includes("gmscitem")).sort();
                  
        setAppState((prevState) => ({ ...prevState, currency: data[0], scart: keys.length }));
        //this.setState({ vouchers: data, loading: false });
    }

    useEffect(() => {
        getCurrenciesAsync();
    }, []);


    return <header style={{ marginBottom: '24px' }}>
        <div className="logo-container">
            <img src="./logo-eurocom-europe.png" onClick={() => history.push('/')} />            
            <div className="logo-container-right">
                <span><i>PREMIUM - CUSTOM MADE - INNOVATIVE</i></span>                
                <p><Icon name='phone' color='grey' /> <a style={{ color: '#aeaeb0' }} href="tel:+420724583823">+420 724 583 823</a></p>
                {/*<p><Icon name='facebook square' color='grey' /><Icon  name='youtube square' color='grey' style={{ marginRight: '-3px' }} /></p>*/}
            </div>
        </div>
        <Menubar className='nav-menubar' model={items} end={end} />
    </header>;


    

}
//export default withGlobalState(NavMenu)

